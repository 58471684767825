import React, { useState, useEffect } from 'react';
import { Button, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TablePagination,
    Paper, 
    CircularProgress,
    Menu,
    MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Snackbar, Alert, Typography, Switch, FormControlLabel, Grid } from '@mui/material';
import ModelDetailsModal from './ModelDetailsModal.js';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { setModelId } from './redux/actions/riskActions';
import { useAuth0 } from '@auth0/auth0-react';

export const fetchModels = async () => {
    const response = await fetch('/.netlify/functions/FetchModelInventory');
    const result = await response.json();
   

    if (result.success) {
      return result.data;
    } else {
      console.error('Error fetching models:', result.error);
      return [];
    }
  }; 

function ModelInventory({ onSelectModel }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState({ model_name: '' });
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'error', 'warning', 'info', 'success'
  const [showCompleted, setShowCompleted] = useState(false);
  const { user } = useAuth0();


useEffect(() => {
    if (user) {
    fetchModels().then(data => {
        
        const updatedData = data.map(model => {
            return fetch('/.netlify/functions/manageAssessment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ modelId: model.model_uuid, operation: 'fetch' })
            })
            .then(response => response.json())
            .then(assessmentData => {
                if (assessmentData && assessmentData.currentStep) {
                    return { ...model, ...assessmentData };
                } else {
                    return { ...model, currentStep: 'Not Started', assessmentStatus: 'Start Risk Assessment' };
                }
            })
            .catch(error => {
                console.error('Error fetching assessment data:', error);
                return { ...model, currentStep: 'Not Started', assessmentStatus: 'Start Risk Assessment' };
            });
        });

        Promise.all(updatedData).then(models => {
            const filteredData = showCompleted ? models : models.filter(model => model.assessmentStatus !== 'Completed');
            setModels(filteredData);
            setIsLoading(false);
        });
    });
}
}, [user,showCompleted]);

  const handleViewDetails = (model) => {
    setSelectedModel(model); 
    navigate(`/model-details/${model.model_uuid}`);
  };
  

  
const handleModelSelection = async (model) => {
  onSelectModel(model);

  console.log ('model to handle in inventory', model)

  dispatch(setModelId(model.model_uuid, model.model_name));

  try {
      const response = await fetch('/.netlify/functions/manageAssessment', {
          method: 'POST',
          body: JSON.stringify({ modelId: model.model_uuid,assessmentUuid:model.assessment_uuid, operation: 'fetch' }),
          headers: { 'Content-Type': 'application/json' },
      });

      let assessmentUuid;
      if (!response.ok) {
          const createResponse = await fetch('/.netlify/functions/manageAssessment', {
              method: 'POST',
              body: JSON.stringify({ modelId: model.model_uuid, operation: 'update', currentStep: 'contextAssessment', assessmentStatus:'onGoing' }),
              headers: { 'Content-Type': 'application/json' },
          });

          if (!createResponse.ok) {
              throw new Error('Failed to create a new assessment.');
          }

          const createResult = await createResponse.json();
          assessmentUuid = createResult.assessmentUuid;
          handleSnackbarOpen('New risk assessment initiated.', 'success');
      } else {
          const result = await response.json();
          assessmentUuid = result.assessmentUuid;
          handleSnackbarOpen('Assessment loaded successfully.', 'success');
      }

 
      navigate(`/risk-form/${model.model_uuid}/${model.model_name}/${assessmentUuid}`);
  } catch (error) {
      console.error('Error handling model selection:', error);
      handleSnackbarOpen('Failed to process model selection.', 'error');
  }
};

    
  const [anchorEl, setAnchorEl] = useState(null);
const [activeModel, setActiveModel] = useState(null);

const handleClick = (event, model) => {
    setAnchorEl(event.currentTarget);
    setActiveModel(model);
};

const handleClose = () => {
    setAnchorEl(null);
};
const handleDeleteConfirmation = () => {
    setDeleteDialogOpen(true);
  };
  

const handleDeleteModel = async () => {
    const response = await fetch(`/.netlify/functions/deleteModel?uuid=${activeModel.model_uuid}`, {
      method: 'DELETE',
    });
    const result = await response.json();
    if (result.success) {
      setModels(models.filter(model => model.model_uuid !== activeModel.model_uuid));
    } else {
      console.error('Error deleting model:', result.error);
    }
  
    setDeleteDialogOpen(false);
  };
  const toTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1') // insert a space before all caps
      .replace(/^./, (str) => str.toUpperCase()) // uppercase the first character
      .trim(); // remove any leading/trailing spaces that may have been inserted
  };
  const handleSnackbarOpen = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
};




  return (
    <>
     
         <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography style={{ fontWeight: 'bold' }} variant="h6">Model Inventory</Typography>
                {isLoading && (
                    <CircularProgress style={{ marginRight: '20px' }} />
                )}
            </Paper>
            {!isLoading && (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <Button
                    variant="contained" 
                    style={{ backgroundColor: '#f1cf94', color: '#1f2123' }}
                    size="small" 
                    onClick={() => navigate("/model-form")}>
                        Add Model Card
                    </Button>
                </div>
                <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                    <Table stickyHeader>
                     
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Model Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Assessment Status</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Current Step</TableCell>
                                 <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                            </TableRow>
                       
                             <TableRow>
                                <TableCell style={{  padding: '0' }} colSpan={2}></TableCell> {/* Empty cells with no border */}
                                <TableCell style={{ padding: '8px 0' }}>
                                    <Grid container alignItems="center">
                                        <FormControlLabel
                                            control={<Switch checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)} />}
                                            label={<Typography component="span" style={{ fontWeight: 'bold', fontSize: '0.625rem' }}>Show Completed</Typography>}
                                        />
                                    </Grid>
                                </TableCell>
                                <TableCell style={{ padding: '0' }} colSpan={3}></TableCell> 
                            </TableRow>
                            </TableHead>
                        <TableBody>
                            {models.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model, i) => (
                                <TableRow key={i} style={{ cursor: 'pointer' }} hover>
                                    <TableCell>{model.model_name}</TableCell>
                                     <TableCell>
                                    <Link 
                                        component="button"
                                        variant="body2"
                                        onClick={(e) => {
                                        e.preventDefault();
                                        handleModelSelection(model);
                                        }}
                                    >
                                        {/* {toTitleCase(assessmentStatus[model.model_uuid]?.step || 'Start Risk Assessment')}   */}
                                        {toTitleCase(model.assessment_status || 'Start Risk Assessment')}
                                    </Link>
                                    </TableCell>
                                    
                                    <TableCell>{toTitleCase(model.currentStep)}</TableCell>
                                    
                                    <TableCell>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ backgroundColor: '#1f2123', color: 'white' ,textTransform: 'none'  }}
                                            onClick={(e) => handleClick(e, model)}
                                            endIcon={<ArrowDropDownIcon />}
                                        >
                                            Actions
                                        </Button>
                                       
                                        <Menu
                                        id="action-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        
                                        <MenuItem onClick={() => {
                                            handleViewDetails(activeModel);
                                            handleClose();
                                            setAnchorEl(null); // Clear anchorEl
                                        }}>View Details</MenuItem>
                                        <MenuItem onClick={() => {
                                            navigate(`/model-form/update/${activeModel.model_uuid}`);
                                            handleClose();
                                            setAnchorEl(null); // Clear anchorEl
                                        }}>Update Model Card</MenuItem>
                                        <MenuItem onClick={() => {
                                            handleDeleteConfirmation();
                                            handleClose();
                                            setAnchorEl(null); // Clear anchorEl
                                        }}>Delete Model</MenuItem>
                                    </Menu>



                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={models.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </TableContainer>
                {selectedModel.model_name && (
                    <ModelDetailsModal modelName={selectedModel.model_name} />
                )}
            </div>
        )}
        <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Are you sure you want to delete the model with the name "{activeModel ? activeModel.model_name : ''}"?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
        </Button>
        <Button onClick={handleDeleteModel} color="primary" autoFocus>
            Confirm
        </Button>
    </DialogActions>
</Dialog>
<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
    </Alert>
</Snackbar>
    </>
    
);

}

export default ModelInventory;
