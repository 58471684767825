import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent,  Grid, Typography,  Paper,Stepper, Step, StepLabel, Button,LinearProgress} from '@mui/material';
import { useParams } from 'react-router-dom';
import RiskCard from './riskCard.js';
import SecurityActionPlan from './securityActionPlan.js';
import SecurityRiskCatalogue from './securityRiskCatalogue.js';
import ContextAssessment from './contextAssessment.js';
import SecurityRiskAssessment from './securityRiskAssessment.js';
import AssessmentCompleted from './AssessmentCompleted.js';
//import TabStepper from './TabStepper';
import { fetchThemes } from './redux/actions/themeActions'; 
import { fetchRiskCardData, updateAssessmentStep, updateAssessmentStatus, setSection } from './redux/actions/riskActions';
import { fetchContextData } from './redux/actions/contextActions';

import contextForm from './contextForm.json'
import riskForm from './riskForm.json'


function RiskCardForm () {

  //const [isLoading, setIsLoading] = useState(true);
  let { modelId, modelName, assessmentUuid } = useParams();
  const dispatch = useDispatch();

 
  const securitySteps = ['threatsAndVulnerabilities', 'securityRisks', 'securityControls'];
  const steps = [
    { label: 'contextAssessment', sections: Object.keys(contextForm) },
    { label: 'modelRisks', sections: Object.keys(riskForm) },
    { label: 'securityAssessment', sections: securitySteps },
    { label: 'completed', sections: [] },
  ];
  const [activeStep, setActiveStep] = useState(0);
  //const [activeSectionName, setActiveSectionName] = useState('');
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
 // const [securityStep, setSecurityStep] = useState(0);
  
  const riskFormData = useSelector(state => state.risks.models[modelId]?.riskFormData || {});
 
  const modelRisksProgress = steps[1].sections.reduce((acc, section) => {
    acc[section] = riskFormData[section]?.assessmentProgress || 0;
    return acc;
  }, {});
  
  
  const contextData = useSelector(state => state.context.data[modelId] || {});
  const narratives = useMemo(() => {
    return Object.keys(contextData).reduce((acc, section) => {
        const narrative = contextData[section]?.narrative ? contextData[section].narrative : `No narrative for ${section}`;
        acc[section] = narrative;
        return acc;
    }, {});
}, [contextData]);



  
useEffect(() => {
  dispatch(fetchThemes());
  dispatch(fetchContextData(modelId, assessmentUuid));
  dispatch(fetchRiskCardData(modelId, assessmentUuid));
}, [dispatch, modelId, assessmentUuid]);

 
console.log('RCF Current Step:', activeStep);


  const handleStepChange = (stepIndex, sectionIndex = 0) => {
    setActiveStep(stepIndex);
    setActiveSectionIndex(sectionIndex);
    const sectionName = steps[stepIndex].sections[sectionIndex];

    if (sectionName) {
        dispatch(setSection(sectionName, modelId));
    }

    // Update the assessment step
    const currentStep = steps[stepIndex].label;
    dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));

    // Update the assessment status
    const assessmentStatus = currentStep === 'completed' ? 'Completed' : 'On-Going';
    dispatch(updateAssessmentStatus(modelId, assessmentUuid, assessmentStatus));
};

  const handleSectionChange = (sectionIndex) => {
    setActiveSectionIndex(sectionIndex);
    const sectionName = steps[activeStep].sections[sectionIndex];
    if (sectionName) {
      dispatch(setSection(sectionName, modelId));
    }
  };



const handleNext = () => {
  const currentSections = steps[activeStep].sections;

  if (activeSectionIndex < currentSections.length - 1) {
    // Move to the next section within the current step
    handleSectionChange(activeSectionIndex + 1);
  } else {
    // Move to the next step
    const nextIndex = activeStep + 1;
    if (nextIndex < steps.length) {
      handleStepChange(nextIndex);

      const nextStep = steps[nextIndex].label;
      dispatch(updateAssessmentStep(modelId, assessmentUuid, nextStep));

      const assessmentStatus = nextStep === 'completed' ? 'Completed' : 'On-Going';
      console.log(`Dispatching status update for ${assessmentStatus}`);
      dispatch(updateAssessmentStatus(modelId, assessmentUuid, assessmentStatus));
    }
  }
};
const handleBack = () => {
  if (activeSectionIndex > 0) {
    // Move to the previous section within the current step
    handleSectionChange(activeSectionIndex - 1);
  } else {
    // Move to the previous step
    const prevIndex = activeStep - 1;
    if (prevIndex >= 0) {
      handleStepChange(prevIndex, steps[prevIndex].sections.length - 1);

      const currentStep = steps[prevIndex].label;
      dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));

      const assessmentStatus = (prevIndex === 0 || currentStep !== 'completed') ? 'On-Going' : 'Completed';
      dispatch(updateAssessmentStatus(modelId, assessmentUuid, assessmentStatus));
    }
  }
};
const toTitleCase = (str) => {
  // Split the string into words and handle camelCase by inserting spaces before capital letters
  return str
    .replace(/([A-Z])/g, ' $1') // Adds space before capital letters
    .replace(/([A-Z]+)/g, (match) => match.toUpperCase()) // Ensure each word is capitalized
    .trim()
    .split(/\s+/) // Split based on whitespace
    .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' '); // Join words back with space
};

//const titleCasedSteps = steps.map(step => toTitleCase(step.label));
const titleCasedSecuritySteps = securitySteps.map(step => toTitleCase(step));
 

return (
  <Grid container spacing={2}>
            <Grid item xs={3}>
                <Paper elevation={2} style={{ padding: '16px', height: '100%' }}>
                    <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    onClick={() => handleStepChange(index)}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5',
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    {toTitleCase(step.label)}
                                </StepLabel>
                                {index === activeStep && step.sections.map((subStep, sectionIdx) => (
                                    <Step key={subStep} sx={{ pl: 4 }}>
                                        <StepLabel
                                            onClick={() => handleSectionChange(sectionIdx)}
                                            sx={{
                                                backgroundColor: activeSectionIndex === sectionIdx ? '#f0f0f0' : 'inherit',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                },
                                            }}
                                        >
                                            {toTitleCase(subStep)}
                                        </StepLabel>
                                        {step.label === 'modelRisks' && (
                                            <LinearProgress
                                                variant="determinate"
                                                value={modelRisksProgress[subStep] || 0}
                                                style={{ marginTop: '8px', width: '80%' }}
                                            />
                                        )}
                                    </Step>
                                ))}
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
            </Grid>

             
                    <Grid item xs={9}  style={{ padding: '8px 24px' }}>
                    <Paper elevation={2} style={{ width: '100%' }}>
                        <Typography variant="h5" style={{ padding: '8px 16px', display: 'flex',  alignItems: 'center' }}>
                            Risk assessment for {toTitleCase(modelName)} 
                            {/* <span style={{ marginLeft: 10 }}>({activeSectionIndex + 1}/{steps.length})</span> */}
                        </Typography>
                      </Paper>
     
  

      
      <Card elevation={2} style={{ height: '100%' }}>

       
         <CardContent>

            {activeStep === 0 && (
              <ContextAssessment
                modelId={modelId}
                assessmentUuid={assessmentUuid}
                sectionName={steps[activeStep].sections[activeSectionIndex]}
              />
            )}
            {activeStep === 1 && (
              <RiskCard
                modelId={modelId}
                assessmentUuid={assessmentUuid}
                narratives={narratives}
                sectionName={steps[activeStep].sections[activeSectionIndex]}
              />
            )}
         
         {activeStep === 2 && (
             <>
              <Paper elevation={2} style={{ width: '100%', padding: '8px 24px' }}>
              <Typography variant="h5" style={{ padding: '8px 24px' }}>
                  {titleCasedSecuritySteps[activeSectionIndex]}
                </Typography>
            {/* <TabStepper
                labels={titleCasedSecuritySteps}
                activeTab={securityStep}
                onStepChange={handleSecurityStepChange}
            /> */}
        </Paper>
        
        {activeSectionIndex === 0 && (
                  <SecurityRiskAssessment modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
                {activeSectionIndex === 1 && (
                  <SecurityRiskCatalogue modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
                {activeSectionIndex === 2 && (
                  <SecurityActionPlan modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
        </>
            
          )}
       
        {activeStep === 3 && <AssessmentCompleted modelId={modelId} modelName={modelName} assessmentUuid={assessmentUuid} />}
   
      </CardContent>
      <Grid container justifyContent="space-between" style={{ padding: '16px' }}>
            <Button onClick={handleBack} disabled={activeStep === 0 && activeSectionIndex === 0}>
              Back
            </Button>
            <Button onClick={handleNext} disabled={activeStep === steps.length - 1 && activeSectionIndex === steps[activeStep].sections.length - 1}>
              Next
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default RiskCardForm;
