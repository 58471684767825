import { 
  INITIALIZE_MODEL,SET_FORM_DATA, SET_STEP,  SET_SECTION, FETCH_RISKS_SUCCESS,FETCH_MODEL_RISKS_START, FETCH_MODEL_RISKS_FAILURE, FETCH_MODEL_RISKS_SUCCESS, TOGGLE_SELECTED_THEME,  
  SET_RISKS,HANDLE_SELECTED_RISKS_UPDATE,SET_RISK_EVALUATION,SET_RISK_PRIORITY_START,  SET_RISK_PRIORITY_SUCCESS,SET_RISK_PRIORITY_FAILURE,UPDATE_RISK_PRIORITY,
  UPDATE_RISK_EVALUATION,SET_MODEL_ID,UPDATE_RISKS_AFTER_SELECTION, SAVE_SELECTED_RISKS, DISCARD_RISKS, 
  DISCARD_UNSELECTED_RISKS,FETCH_RISKS_START, FETCH_RISKS_FAILURE,UPDATE_RISK_NAME, UPDATE_CONTROL_IN_SELECTED_RISK,
   FETCH_RISK_CARD_DATA_START, FETCH_RISK_CARD_DATA_SUCCESS,FETCH_RISK_CARD_DATA_FAILURE, FETCH_RISK_EVALUATIONS,
    MOVE_RISK_TO_SAVED, UPDATE_SAVED_RISK,DISCARD_SAVED_RISK, RESET_RISKS_STATE,   FETCH_SECTION_SPECIFIC_RISKS_START,
    FETCH_SECTION_SPECIFIC_RISKS_SUCCESS, FETCH_SECTION_SPECIFIC_RISKS_FAILURE, UPDATE_ASSESSMENT_STEP_SUCCESS,ASSESSMENT_STATUS_UPDATED,ASSESSMENT_STATUS_FAILED,
  REFRESH_RISKS_SUCCESS, FETCH_MODELS_START, FETCH_MODELS_SUCCESS, FETCH_MODELS_FAILURE, UPDATE_LOADING_MESSAGE,UPDATE_SECTION_PROGRESS

} from './actionTypes'; 
//import riskForm from '../../riskForm.json'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchThemes } from './themeActions';
import { setSavedControls, fetchSavedControlEvaluations } from './controlActions';
import { fetchSecurityRisks, fetchSecurityControls } from './securityActions';
import _ from 'lodash';


export const fetchAllModels = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_MODELS_START });
  dispatch(updateLoadingMessage('Fetching models...'));

  try {
    const response = await fetch('/.netlify/functions/FetchModelInventory');
    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      const models = jsonResponse.data;
     

          await Promise.all(models.map(async (model) => {
              // Initialize model only if it does not exist in the state
              if (!getState().risks.models[model.model_uuid]) {
                  dispatch({
                      type: INITIALIZE_MODEL,
                      payload: {
                          modelId: model.model_uuid,
                          modelName: model.model_name,
                          assessmentUuid: model.assessment_uuid,
                          assessmentStatus:model.assessment_status
                      }
                  });
              }

        dispatch(updateLoadingMessage(`Fetching themes for ${model.model_name}...`));
        await dispatch(fetchThemes()); 
        dispatch(updateLoadingMessage(`Fetching risk cards for ${model.model_name}...`));
        await dispatch(fetchRiskCardData(model.model_uuid, model.assessment_uuid));
        dispatch(updateLoadingMessage(`Fetching risks for ${model.model_name}...`));
        await dispatch(fetchModelRisks(model.model_uuid, model.assessment_uuid));
        dispatch(updateLoadingMessage(`Fetching action plans for ${model.model_name}...`));
        await dispatch(setSavedControls(model.model_uuid, model.assessment_uuid));
        await dispatch(fetchSavedControlEvaluations(model.model_uuid, model.assessment_uuid));

        dispatch(updateLoadingMessage(`Fetching security risks for ${model.model_name}...`));
        await dispatch(fetchSecurityRisks(model.model_uuid, model.assessment_uuid));
        
        dispatch(updateLoadingMessage(`Fetching security controls for ${model.model_name}...`));
        model.risks.forEach(async risk => {
          await dispatch(fetchSecurityControls(model.model_uuid, risk.risk_id));
        });
      }));

      dispatch({ type: FETCH_MODELS_SUCCESS, payload: models });
      dispatch(updateLoadingMessage(''));
    } else {
      throw new Error('Failed to fetch models: ' + jsonResponse.message);
    }
  } catch (error) {
    console.error('Fetch models failed:', error);
    dispatch({ type: FETCH_MODELS_FAILURE, payload: error.message });
    dispatch(updateLoadingMessage('Failed to load data.'));
  }
};

// Helper action to update loading message
const updateLoadingMessage = (message) => ({
  type: UPDATE_LOADING_MESSAGE,
  payload: message
});



export const fetchModelRisks = (modelId, assessmentUuid) => async (dispatch) => {
  dispatch({ type: FETCH_MODEL_RISKS_START, payload: { modelId } });
  try {
    const response = await fetch('/.netlify/functions/fetchModelRisks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ modelId, assessmentUuid })  // Make sure the API expects these field names
    });
    const data = await response.json();

    if (data.success) {
      dispatch({ type: FETCH_MODEL_RISKS_SUCCESS, payload: { modelId, risks: data.data } });
    } else {
      throw new Error(data.message || "Failed to fetch risks.");
    }
  } catch (error) {
    dispatch({ type: FETCH_MODEL_RISKS_FAILURE, payload: { modelId, error: error.message } });
  }
};




export const fetchRisksFromLLM = (riskFormData, sectionName, activeSections,  sectionToUpdate, sectionsToFetch, modelId) => {
  return async (dispatch) => {
    dispatch(fetchRisksStart({ modelId, sectionsToFetch }));
    try {
        const body = {
          riskFormData,
          activeSections,
          sectionsToFetch
        };
        console.log('body sent to fetch risks', body)
        if (sectionToUpdate) {
          body.sectionToUpdate = sectionToUpdate;
        }

        const response = await fetch('/.netlify/functions/fetchRisksForSection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
          if (!response.ok) {
              throw new Error(`Error fetching risks: ${response.status} ${response.statusText}`);
          }

          const fetchedRisks = await response.json();
          console.log('fetchedRisks:', fetchedRisks);
          if (!Array.isArray(fetchedRisks)) {
            throw new Error('Unexpected response format: fetchedRisks is not an array');
          }
          console.log('Dispatching fetchRisksFromLLM');
          dispatch(fetchRisksSuccess(fetchedRisks, sectionsToFetch, modelId));
      } catch (error) {
          console.error('Fetch error:', error.message);
          dispatch(fetchRisksFailure(error.message));
      }
  };
};

export const fetchNewRisks = (modelId, sectionName, sectionData, existingRisks) => async dispatch => {
  try {
    const response = await fetch(`/.netlify/functions/generateRisks`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ modelId, sectionName, sectionData, existingRisks }),
    });
    const newRisks = await response.json();
    console.log ('received risks to refresh to dispatch', newRisks)
    dispatch({ type: REFRESH_RISKS_SUCCESS, payload: { modelId, sectionName, newRisks  } });
  } catch (error) {
    console.error('Failed to fetch new risks:', error);
  }
};


export const updateActiveSections = (modelId, sections) => {
  // Log the action payload before dispatching
  console.log('Dispatching updateActiveSections with:', { modelId, sections });

  return {
    type: 'risks/updateActiveSections',
    payload: { modelId, sections },
  };
};





export const fetchRisksForModel = createAsyncThunk(
  'risks/fetchRisksForModel',
  async ({ modelId, assessmentUuid, sectionName }, { rejectWithValue }) => {
    try {
      if (!modelId || !assessmentUuid) {
        throw new Error('Model ID or Assessment UUID is missing');
      }
      const response = await fetch('/.netlify/functions/fetchRisks', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, assessmentUuid, sectionName }),
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch risks for model ${modelId} and assessment ${assessmentUuid} and section ${sectionName} `);
      }
      const fetchedRisks = await response.json();
      console.log ('fetchedrisks from db to dispatch to reducer', fetchedRisks)
      return fetchedRisks; 
    } catch (error) {
      console.error(`Error fetching risks for model ${modelId} and assessment ${assessmentUuid}:`, error);
      return rejectWithValue(error.message); // This will be the payload for the rejected action
    }
  }
);


export const setModelId = (modelId, modelName) => {
  return {
    type: SET_MODEL_ID,
    payload: {modelId, modelName },
  };
};


export const fetchRisksSuccess = (fetchedRisks, sectionsToFetch, modelId) => ({
  type: FETCH_RISKS_SUCCESS,
  payload: { fetchedRisks, sectionsToFetch, modelId }
});


export const fetchRisksFailure = (error, sectionName, modelId) => ({
  type: FETCH_RISKS_FAILURE,
  payload: { error, sectionName, modelId },
});

export const saveSelectedRisks = (risks, modelId) => ({
  type: SAVE_SELECTED_RISKS,
  payload: { risks, modelId }
});

export const discardRisk = (risk, modelId, sectionName) => ({
  type: DISCARD_RISKS,
  payload:  {risk, modelId, sectionName}
});

export const discardUnselectedRisks = (risksToDiscard, modelId) => {
  return {
    type: DISCARD_UNSELECTED_RISKS,
    payload: { risksToDiscard, modelId },
  };
};


// export const setRiskPriority = (modelId, riskId,sectionName, riskName, numericThemeId,themeName, evaluationData) => {

//   console.log ('received riskName in setRiskPriority', riskName)
//   console.log ('received themename',themeName)
//   console.log ('received eval data', evaluationData)
//   return async (dispatch) => {
//     try {
//       const payload = JSON.stringify({
//         riskName,
//         themeName, 
//         evaluationData
//       });

//       console.log("Payload sent for evaluation:", payload);
//       const response = await fetch('/.netlify/functions/prioritizeRisks', {
//         method: 'POST',
//         body: payload,
//         headers: { 'Content-Type': 'application/json' },
//       });

//       if (!response.ok) {
//         const text = await response.text();
//         console.error("Failed evaluation with status:", response.status, "and message:", text);
//         return;
//       }

//       const data = await response.json();
//       const riskPriorityData = data;

//       const savePayload = JSON.stringify({
//         riskId,
//         themeId: numericThemeId,
//         priority: riskPriorityData.priority,
//         rationale: riskPriorityData.rationale,
//         modelUuid: modelId
//       });

//       const prioritySaveResponse = await fetch('/.netlify/functions/savePriority', {
//         method: 'POST',
//         body: savePayload,
//         headers: { 'Content-Type': 'application/json' },
//       });

//       if (!prioritySaveResponse.ok) throw new Error('Failed to save priority and rationale');


//       dispatch({
//         type: SET_RISK_PRIORITY,
//         payload: {
//           modelId,
//           riskId,
//           sectionName,
//           riskName,
//           numericThemeId,
//           themeName,
//           priority: riskPriorityData.priority, // Assuming 'priority' key exists in returned data
//           rationale: riskPriorityData.rationale // Assuming 'rationale' key exists in returned data
//         }
//       });
//     } catch (error) {
//       console.error("Failed to prioritize risk:", error);
//     }
//   };
// };

export const setRiskPriority = (modelId, riskId, sectionName, riskName, numericThemeId, themeName, evaluationData) => {
  return async (dispatch) => {
    dispatch({
      type: SET_RISK_PRIORITY_START,
      payload: { modelId, sectionName, riskId } // Ensuring modelId is included in the payload
    });
    try {
      const payload = JSON.stringify({
        riskName,
        themeName, 
        evaluationData
      });

      const response = await fetch('/.netlify/functions/prioritizeRisks', {
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('HTTP error ' + response.statusText);
      }

      const data = await response.json();
      dispatch({
        type: SET_RISK_PRIORITY_SUCCESS,
        payload: {
          modelId,
          riskId,
          sectionName,
          numericThemeId,
          themeName,
          priority: data.priority,
          rationale: data.rationale
        }
      });
      return Promise.resolve();  // Successfully resolved the promise
    } catch (error) {
      dispatch({
        type: SET_RISK_PRIORITY_FAILURE,
        payload: { modelId, sectionName,riskId, error: error.message }
      });
      return Promise.reject(error);  // Reject the promise if there's an error
    }
  };
};



export const updateRiskPriority = (modelId,riskId, sectionName, priority) => {
  return {
    type: UPDATE_RISK_PRIORITY,
    payload: { modelId,riskId,sectionName,priority },
  };
};
export const setSection = (sectionName, modelId) => ({
  type: SET_SECTION,
  payload: { sectionName, modelId }
});



export const setStep = ( modelId, sectionName,stepName) => ({
  type: SET_STEP,
  payload: { modelId, sectionName, stepName }
});

// Redux Action
export const updateAssessmentStep = (modelId, assessmentUuid,step) => async (dispatch) => {
  try {
    const response = await fetch('/.netlify/functions/manageAssessment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({modelId,assessmentUuid, currentStep: step, operation: 'update' }),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to update step');
    dispatch({
      type: UPDATE_ASSESSMENT_STEP_SUCCESS,
      payload: { modelId, currentStep: step }
    });
  } catch (error) {
    console.error('Error updating step:', error);
  }
};


export const setFormData = (data, modelId) => ({
    type: SET_FORM_DATA,
    payload: {...data, modelId }
});



export const setRisks = (risks) => ({
    type: SET_RISKS,
    payload: risks
});


export const toggleSelectedTheme = (theme) => ({
    type: TOGGLE_SELECTED_THEME,
    payload: theme
});


export const handleSelectedRisksUpdate = (selectedRisk, modelId) => {
  return {
    type: HANDLE_SELECTED_RISKS_UPDATE,
    payload: { selectedRisk, modelId}
  };
};
export const updateRisksAfterSelection = (updatedRisks) => {
  return {
      type: UPDATE_RISKS_AFTER_SELECTION,
      payload: updatedRisks,
  };
};

export const fetchRisksStart = ({ modelId, sectionsToFetch }) => ({
  type: FETCH_RISKS_START,
  payload: { modelId, sectionsToFetch },
});


export const setRiskEvaluation = (modelId,riskId, riskName, sectionName, themeId, evaluationData) => ({
  type: SET_RISK_EVALUATION,
  payload: { modelId, riskId,riskName, themeId, evaluationData,sectionName }
});

export const updateRiskEvaluation = (modelId, riskId, sectionName, themeId, attribute, property, value, priority, rationale) => ({
  type: UPDATE_RISK_EVALUATION,
  payload: { modelId, riskId, sectionName,themeId, attribute, property, value, priority, rationale}
});


// Action to update the name of a risk that hasn't been saved to the backend yet
export const updateRiskName = (modelId, sectionName, originalName, newName) => ({
  type: UPDATE_RISK_NAME,
  payload: { modelId, sectionName, originalName, newName }
});


export const updateRiskWithControls = (modelId, riskName, controlData) => dispatch => {
  dispatch({
    type: UPDATE_CONTROL_IN_SELECTED_RISK,
    payload: { modelId, riskName, controlData },
  });
};
export const fetchRiskCardDataStart = () => ({
  type: FETCH_RISK_CARD_DATA_START,
});

// Action creator for a successful fetch operation
export const fetchRiskCardDataSuccess = (data, modelId) => ({
  type: FETCH_RISK_CARD_DATA_SUCCESS,
  payload: { data, modelId },
});

// Action creator for a failed fetch operation
export const fetchRiskCardDataFailure = (error, modelId) => ({
  type: FETCH_RISK_CARD_DATA_FAILURE,
  payload: { error, modelId },
});

export const fetchRiskCardData = (modelId, assessmentUuid) => async (dispatch) => {
  dispatch(fetchRiskCardDataStart());

  try {
    console.log(`Starting fetch for modelId: ${modelId}, assessmentUuid: ${assessmentUuid}`);
    
    const response = await fetch('/.netlify/functions/fetchRiskCard', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ model_uuid: modelId, assessment_uuid: assessmentUuid }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
   
    
    const { data } = result;
    if (!data || data.length === 0) {
      throw new Error('No data returned from the API');
    }
    

    const item = data; 
    
    console.log('RC Data from DB to transform', item);

    // Transform fetched data into the format suitable for the state
    const transformedData = transformFetchedData(item);
    console.log('Transformed data:', transformedData);

    dispatch(fetchRiskCardDataSuccess(transformedData, modelId));
  } catch (error) {
    console.error('Error during fetch or transformation:', error);
    dispatch(fetchRiskCardDataFailure(error.toString(), modelId));
  }
};

function toCamelCase(str) {
  return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

// function transformFetchedData(fetchedData) {
//   const transformed = {};
//   const skipKeys = new Set([
//     'assessmentProgress',
//     'currentStep',
//     'sectionRisks',
//     'assessment_uuid',
//     'model_uuid',
//     'last_updated',
//     'updated_at'
//   ]);

//   if (!fetchedData || typeof fetchedData !== 'object') {
//     console.log("Fetched data is invalid or not an object.");
//     return transformed;
//   }

//   Object.keys(fetchedData).forEach(section => {
//     if (skipKeys.has(section)) return;

//     const camelCaseSectionName = toCamelCase(section);
//     let sectionData;

//     try {
//       console.log(`Parsing section: ${section}`);
//       sectionData = JSON.parse(fetchedData[section] || '{}');
//       console.log(`Parsed section data for ${section}:`, sectionData);
//     } catch (error) {
//       console.error(`Failed to parse section ${section}:`, error);
//       return;
//     }

//     transformed[camelCaseSectionName] = transformSection(sectionData);
//   });

//   console.log("Final transformed data:", transformed);
//   return transformed;
// }

// function transformSection(sectionData) {
//   if (Array.isArray(sectionData)) {
//     console.log("Section data is an array:", sectionData);
//     return transformArray(sectionData);
//   } else if (typeof sectionData === 'object') {
//     console.log("Section data is an object:", sectionData);
//     return transformObject(sectionData);
//   } else {
//     console.log("Section data is neither array nor object, returning empty array.");
//     return [];
//   }
// }

// function transformArray(fields) {
//   console.log("Transforming array of fields:", fields);
//   return fields.map(field => ({
//     name: field.name,
//     value: field.value || (field.type === 'multiselect' ? [] : ''),
//     type: field.type,
//     options: field.options || [],
//     description: field.description || ""
//   }));
// }

// function transformObject(fields) {
//   console.log("Transforming object fields:", fields);
//   const result = {};

//   Object.entries(fields).forEach(([key, value]) => {
//     if (typeof value === 'object' && 'type' in value) {
//       console.log(`Processing field ${key} with value:`, value);
//       if (!result[key]) {
//         result[key] = [];
//       }
//       result[key]={
//         name: value.name,  // Use the field's name if available, otherwise fallback to the key
//         value: value.value || (value.type === 'multiselect' ? [] : ''), 
//         type: value.type,
//         options: value.options || [],
//         description: value.description || ""
//       };
//     } else if (typeof value === 'object') {
//       // Recursively transform nested objects
//       console.log(`Recursively transforming object key ${key} with value:`, value);
//       result[key] = transformObject(value);  // Keep the category name as the key
//     } else {
//       console.log(`Skipping key ${key} with unrecognized value type.`);
//     }
//   });

//   return result;
// }


// function transformFetchedData(fetchedData) {
//   const transformed = {};
//   const skipKeys = new Set([
//     'assessmentProgress',
//     'currentStep',
//     'sectionRisks',
//     'assessment_uuid',
//     'model_uuid',
//     'last_updated',
//     'updated_at'
//   ]);

//   if (!fetchedData || typeof fetchedData !== 'object') {
//     console.log("Fetched data is invalid or not an object.");
//     return transformed;
//   }

//   Object.keys(fetchedData).forEach(section => {
//     if (skipKeys.has(section)) return;

//     const camelCaseSectionName = toCamelCase(section);
//     let sectionData;

//     try {
//       console.log(`Parsing section: ${section}`);
//       sectionData = JSON.parse(fetchedData[section] || '{}');
//       console.log(`Parsed section data for ${section}:`, sectionData);
//     } catch (error) {
//       console.error(`Failed to parse section ${section}:`, error);
//       return;
//     }

//     transformed[camelCaseSectionName] = transformSection(sectionData);
//   });

//   console.log("Final transformed data:", transformed);
//   return transformed;
// }

// function transformSection(sectionData) {
//   if (Array.isArray(sectionData)) {
//     console.log("Section data is an array:", sectionData);
//     return transformArray(sectionData);
//   } else if (typeof sectionData === 'object') {
//     console.log("Section data is an object:", sectionData);
//     return transformObject(sectionData);
//   } else {
//     console.log("Section data is neither array nor object, returning empty array.");
//     return [];
//   }
// }

// function transformArray(fields) {
//   console.log("Transforming array of fields:", fields);
//   return fields.map(field => transformField(field));
// }

// // function transformObject(fields) {
// //   console.log("Transforming object fields:", fields);
// //   const result = {};

// //   Object.entries(fields).forEach(([key, value]) => {
// //     if (Array.isArray(value)) {
// //       console.log(`Transforming array under key ${key}`);
// //       result[key] = transformArray(value);
// //     } else if (typeof value === 'object' && 'type' in value) {
// //       console.log(`Processing field ${key} with value:`, value);
// //       result[key] = transformField(value);
// //     } else if (typeof value === 'object') {
// //       console.log(`Recursively transforming nested object ${key}`);
// //       result[key] = transformObject(value);
// //     } else {
// //       console.log(`Skipping key ${key} with unrecognized value type.`);
// //     }
// //   });

// //   return result;
// // }

// function transformObject(fields) {
//   console.log("Transforming object fields:", fields);
//   const result = {};

//   Object.entries(fields).forEach(([key, value]) => {
//     if (typeof value === 'object' && 'type' in value) {
//       // Process the field correctly, ensuring no duplication
//       result[key] = {
//         name: value.name,
//         value: value.value || (value.type === 'multiselect' ? [] : ''),
//         type: value.type,
//         options: value.options || [],
//         description: value.description || ""
//       };
//     } else if (typeof value === 'object') {
//       // Recursively transform nested objects, but avoid re-adding existing fields
//       result[key] = transformObject(value); // Keep the category name as the key
//     } else {
//       console.log(`Skipping key ${key} with unrecognized value type.`);
//     }
//   });

//   return result;
// }


// function transformField(field) {
//   return {
//     name: field.name,
//     value: field.value || (field.type === 'multiselect' ? [] : ''),
//     type: field.type,
//     options: field.options || [],
//     description: field.description || ""
//   };
// }



function transformFetchedData(fetchedData) {
  const skipKeys = new Set([
    'assessmentProgress',
    'currentStep',
    'sectionRisks',
    'assessment_uuid',
    'model_uuid',
    'last_updated',
    'updated_at'
  ]);

  if (!_.isObject(fetchedData)) {
    console.log("Fetched data is invalid or not an object.");
    return {};
  }

  return _(fetchedData)
    .omit([...skipKeys])
    .mapKeys((value, key) => toCamelCase(key))
    .mapValues(sectionData => {
      try {
        return transformSection(JSON.parse(sectionData || '{}'));
      } catch (error) {
        //console.error(`Failed to parse section ${key}:`, error);
        return {};
      }
    })
    .value();
}

function transformSection(sectionData) {
  if (_.isArray(sectionData)) {
    return transformArray(sectionData);
  } else if (_.isObject(sectionData)) {
    return transformObject(sectionData);
  } else {
    console.log("Section data is neither array nor object, returning empty array.");
    return [];
  }
}

function transformArray(fields) {
  return _.map(fields, field => transformField(field));
}

function transformObject(fields) {
  return _(fields)
    .mapValues(value => {
      if (_.isArray(value)) {
        return transformArray(value);
      } else if (_.isObject(value) && 'type' in value) {
        return transformField(value);
      } else if (_.isObject(value)) {
        return transformObject(value);
      } else {
        console.log(`Skipping unrecognized value type.`);
        return undefined;
      }
    })
    .omitBy(_.isUndefined)  // Remove undefined values that were skipped
    .value();
}

function transformField(field) {
  return {
    name: field.name,
    value: field.value || (field.type === 'multiselect' ? [] : ''),
    type: field.type,
    options: field.options || [],
    description: field.description || ""
  };
}




export const fetchRiskEvaluations = (modelId, assessmentUuid, sectionName) => async (dispatch) => {
  dispatch({ type: FETCH_RISK_EVALUATIONS });
  try {
    const response = await fetch('/.netlify/functions/fetchRiskEvaluation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ modelId, assessmentUuid }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok, status: ${response.status}`);
    }

    const data = await response.json();
    console.log('data into fetch riskeval', data);

    if (!Array.isArray(data.evaluations)) {
      throw new Error('Expected an array of evaluations, but did not receive one.');
    }

    const evaluationsByRiskAndTheme = data.evaluations.reduce((acc, evaluation) => {
      // Create a combined key using risk_name and theme_id to ensure uniqueness
      const key = `${evaluation.risk_id}-${evaluation.theme_id}`;
      (acc[key] = acc[key] || []).push(evaluation);
      return acc;
    }, {});

    dispatch({
      type: 'FETCH_RISK_EVALUATIONS_SUCCESS',
      payload: { modelUuid: modelId, evaluations: evaluationsByRiskAndTheme, sectionName},
    });
  } catch (error) {
    console.error('Error fetching risk evaluations:', error);
    dispatch({ type: 'FETCH_RISK_EVALUATIONS_FAILURE', error: error.toString() });
  }
};


export const moveRiskToSaved = (modelId, sectionName, risk) => ({
  type: MOVE_RISK_TO_SAVED,
  payload: { modelId, sectionName, risk }
});

export const updateSavedRisk = (modelId, riskId,sectionName,newDetails) => ({
  type: UPDATE_SAVED_RISK,
  payload: { modelId, riskId, sectionName,newDetails }
});

export const discardSavedRisk = (riskId, modelId, sectionName) => ({
  type: DISCARD_SAVED_RISK,
  payload: { riskId, modelId, sectionName }
});


export const resetRisksState = () => ({
  type: RESET_RISKS_STATE
});
// export const fetchSectionSpecificRisks = (modelId, sectionName, sectionData, narratives) => async (dispatch) => {
//   dispatch({ type: FETCH_SECTION_SPECIFIC_RISKS_START, payload: { modelId, sectionName } });
//   try {
//     const response = await fetch('/.netlify/functions/generateRisks', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ sectionName, sectionData, narratives }),
//     });
//     if (!response.ok) {
//       throw new Error(`Failed to fetch section-specific risks: ${response.status}`);
//     }
//     const fetchedRisks = await response.json();
//     dispatch({
//       type: FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
//       payload: { modelId, sectionName, risks: fetchedRisks },
//     });
//   } catch (error) {
//     console.error('Error fetching section-specific risks:', error);
//     dispatch({
//       type: FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
//       payload: { modelId, sectionName, error: error.message },
//     });
//   }
// };
// riskActions.js

export const fetchSectionSpecificRisks = (modelId, sectionName, sectionData, narratives) => async (dispatch, getState) => {
  const state = getState();
  const existingRisks = state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || [];

  if (existingRisks.length > 0) {
      // If risks already exist, no need to fetch them again
      return;
  }

  dispatch({ type: FETCH_SECTION_SPECIFIC_RISKS_START, payload: { modelId, sectionName } });

  try {
      const response = await fetch('/.netlify/functions/generateRisks', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sectionName, sectionData, narratives }),
      });

      if (!response.ok) {
          throw new Error(`Failed to fetch section-specific risks: ${response.status}`);
      }

      const fetchedRisks = await response.json();
      dispatch({
          type: FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
          payload: { modelId, sectionName, risks: fetchedRisks },
      });

  } catch (error) {
      console.error('Error fetching section-specific risks:', error);
      dispatch({
          type: FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
          payload: { modelId, sectionName, error: error.message },
      });
  }
};


export const updateAssessmentStatus = (modelId, assessmentUuid,assessmentStatus) => async (dispatch) => {

  console.log(`Attempting to update status: ${assessmentStatus} and Id ${assessmentUuid}`);
  
    try {
    const response = await fetch('/.netlify/functions/manageAssessment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({modelId, assessmentUuid, operation: 'updateStatus', assessmentStatus })
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error);

    console.log ('status received from database', data)
    dispatch(assessmentStatusUpdated(modelId,data.assessmentStatus));
  } catch (error) {
    console.error('Failed to update assessment status:', error);
    dispatch(assessmentStatusFailed(error.toString()));
  }
};


export const assessmentStatusUpdated = (modelId, assessmentStatus) => ({
  type: ASSESSMENT_STATUS_UPDATED,
  payload: {modelId, assessmentStatus}
});

export const assessmentStatusFailed = (error) => ({
  type: ASSESSMENT_STATUS_FAILED,
  payload: error
});
export const updateSectionProgress = (modelId, sectionName, progress) => ({
  type: UPDATE_SECTION_PROGRESS,
  payload: { modelId, sectionName, progress }
});