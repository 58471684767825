// import React, { useEffect } from 'react';
// import netlifyIdentity from 'netlify-identity-widget';
// import { Button, Box, Typography } from '@mui/material';

// // const AuthPage = () => {
// //   useEffect(() => {
// //     netlifyIdentity.init();
// //     const user = netlifyIdentity.currentUser();
// //     if (user) {
// //       window.location.href = '/';
// //     }

// //     netlifyIdentity.on('login', (user) => {
// //       console.log('User logged in:', user);
// //       window.location.href = '/'; // redirect after login
// //     });

// //     netlifyIdentity.on('logout', () => {
// //       console.log('User logged out');
// //     });

// //     return () => {
// //       netlifyIdentity.off('login');
// //       netlifyIdentity.off('logout');
// //     };
// //   }, []);

// const AuthPage = () => {
//   useEffect(() => {
//     netlifyIdentity.init();
//     const user = netlifyIdentity.currentUser();

//     if (user) {
//       window.location.href = '/';
//     }

//     const handleLogin = (user) => {
//       console.log('User logged in:', user);
//       window.location.href = '/'; // redirect after login
//       netlifyIdentity.off('login', handleLogin); // Remove listener after handling
//     };

//     netlifyIdentity.on('login', handleLogin);

//     return () => {
//       netlifyIdentity.off('login', handleLogin); // Ensure listener is removed when component unmounts
//     };
//   }, []);


//   const handleSignIn = () => {
//     netlifyIdentity.open('login');
//   };

//   const handleSignUp = () => {
//     netlifyIdentity.open('signup');
//   };

//   return (
//     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
//       <Typography variant="h4" gutterBottom>
//         Welcome to RiskGPT
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Please sign in or sign up to continue.
//       </Typography>
//       <Box mt={2}>
//         <Button variant="contained" color="primary" onClick={handleSignIn} style={{ marginRight: '10px' }}>
//           Sign In
//         </Button>
//         <Button variant="contained" color="secondary" onClick={handleSignUp}>
//           Sign Up
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AuthPage;
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';

const AuthPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignIn = () => {
    loginWithRedirect();
  };

  const handleSignUp = () => {
    loginWithRedirect({ screen_hint: 'signup' });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom>
        Welcome to RiskGPT
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please sign in or sign up to continue.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSignIn} style={{ marginRight: '10px' }}>
          Sign In
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSignUp}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default AuthPage;

