
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await fetch('/.netlify/functions/listCatalogues');
        if (!response.ok) {
          throw new Error('Failed to fetch files from Netlify Blobs');
        }
        const filesData = await response.json();
        setUploadedFiles(filesData);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchUploadedFiles();
  }, []);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) => {
      const fileType = file.name.split('.').pop().toLowerCase();
      return ['txt', 'csv', 'xls', 'xlsx', 'docx', 'pdf'].includes(fileType);
    });
    setFiles(validFiles);
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const uploadedFilesData = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('/.netlify/functions/blobManager', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        const { fileName, fileType } = await response.json();

        uploadedFilesData.push({
          name: fileName,
          date: new Date().toLocaleString(),
          uploaded: true,
          fileType,
        });
      }

      setUploadedFiles([...uploadedFiles, ...uploadedFilesData]);
      setSnackbarMessage('File uploaded successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error uploading files:', error);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
    } finally {
      setUploading(false);
      setSnackbarOpen(true);
    }
  };



  const handleDelete = async (fileName) => {
    try {
      const response = await fetch('/.netlify/functions/deleteCatalogue', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete file');
      }
  
      setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
      setSnackbarMessage('File deleted successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error deleting file:', error);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };
  

  const handleEmbed = async (fileName, fileType) => {
    try {
      const response = await fetch('/.netlify/functions/processFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName, fileType }),
      });

      if (!response.ok) {
        throw new Error('Failed to embed file');
      }

      setSnackbarMessage('File embedded successfully!');
      setSnackbarSeverity('success');
    
      } catch (error) {
        console.error('Error embedding file:', error);
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    };
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Risk & Control Catalogues
        </Typography>
  
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            border: '1px solid #ccc',
            borderRadius: 2,
            mb: 3,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Upload Catalogues
          </Typography>
         
          <input 
          type="file" 
          multiple 
          onChange={handleFileChange} 
          style={{ display: 'none' }} 
          id="upload-input" 
        />
        <label htmlFor="upload-input">
        <Button
        variant="contained"
          color="primary"
          component="span"
          onClick={handleUpload}
          disabled={uploading}
          startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadOutlinedIcon />}
          sx={{ mt: 2 }}
        >
    {uploading ? 'Uploading...' : 'Upload Files'}
  </Button>
        </label>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Allowed file types: txt, csv, xls, xlsx, docx, pdf
          </Typography>
        </Box>
  
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Date Uploaded</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map((file, idx) => (
                <TableRow key={idx}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.date}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEmbed(file.name, file.fileType)}
                    >
                      <SmartToyOutlinedIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(file.name)}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
  
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  };
  
  export default FileUpload;
  
