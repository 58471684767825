
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Grid, TextField, InputLabel, Select, MenuItem, Chip, Typography, CircularProgress, Paper} from '@mui/material';
import { setFormData, fetchRiskCardData,setStep, updateSectionProgress } from './redux/actions/riskActions';
import { Snackbar, Alert, Tooltip, IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import AutosaveIndicator from './AutoSaveIndicator.js';
import debounce from 'lodash/debounce';
import RiskCatalogue from './RiskCatalogue.js';
import RiskEvaluation from './RiskEvaluation';
import ActionPlan from './ActionPlan.js';
import TabStepper from './TabStepper.js';
import AssessmentCompleted from './AssessmentCompleted.js';
import riskForm from './riskForm.json'
import _ from 'lodash';



const RiskCard = ({ modelId, assessmentUuid, narratives, sectionName }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const riskFormData = useSelector(state => state.risks.models[modelId]?.riskFormData || {});
    
    const validKeys = useMemo(() => {
      return _.mapValues(riskForm, categories => {
          if (Array.isArray(categories)) {
              // If the section is a flat array (like "accuracy"), return the field names directly
              return categories.map(field => field.name);
          } else {
              // If the section has categories, return the category names
              return Object.keys(categories);
          }
      });
  }, []);
  
  
  console.log ('valid keys:', validKeys)
   
    //const [selectedSection, setSelectedSection] = useState(0);
    const tabNames = ['riskCard', 'riskIdentification', 'riskEvaluation', 'actionPlan', 'completed'];
    const [selectedTab, setSelectedTab] = useState(0); 


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [hoverStates, setHoverStates] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);


      
    useEffect(() => {
        if ( modelId && assessmentUuid) {
            setIsLoading(true);
            dispatch(fetchRiskCardData(modelId, assessmentUuid))
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Failed to fetch data:', error);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, modelId,assessmentUuid]);
    
  
//   const flattenFormData = (formData, validKeys) => {
//     const flattenedData = {};

//     Object.entries(formData).forEach(([section, categoriesOrFields]) => {
//         // Check if the section is a flat list of fields like "accuracy"
//         if (Array.isArray(categoriesOrFields)) {
//             // This is a flat list of fields
//             categoriesOrFields.forEach(field => {
//                 if (validKeys[section]?.includes(field.name)) {
//                     if (!flattenedData[section]) flattenedData[section] = [];
//                     flattenedData[section].push(field);
//                 }
//             });
//         } else if (typeof categoriesOrFields === 'object' && categoriesOrFields !== null) {
//             Object.entries(categoriesOrFields).forEach(([category, fields]) => {
//                 // Check if fields are in a flat object or an array
//                 if (Array.isArray(fields)) {
//                     // Handle array of fields (normal case)
//                     fields.forEach(field => {
//                         if (validKeys[section]?.includes(field.name)) {
//                             if (!flattenedData[section]) flattenedData[section] = {};
//                             if (!flattenedData[section][category]) flattenedData[section][category] = [];
                            
//                             flattenedData[section][category].push(field);
//                         }
//                     });
//                 } else if (typeof fields === 'object' && fields !== null) {
//                     // Handle flat object with numeric keys
//                     Object.entries(fields).forEach(([key, field]) => {
//                         if (typeof field === 'object' && field !== null && validKeys[section]?.includes(field.name)) {
//                             if (!flattenedData[section]) flattenedData[section] = {};
//                             if (!flattenedData[section][category]) flattenedData[section][category] = [];
                            
//                             flattenedData[section][category].push(field);
//                         }
//                     });
//                 } else {
//                     console.warn(`Unexpected data structure for category "${category}" in section "${section}":`, fields);
//                 }
//             });
//         } else {
//             console.warn(`Unexpected structure for section "${section}":`, categoriesOrFields);
//         }
//     });

//     console.log('Flattened data to save:', flattenedData);
//     return flattenedData;
// };

const debouncedSave = useMemo(() => debounce(async (section, sectionData) => {
  setIsSaving(true);
  try {
      const response = await fetch('/.netlify/functions/SaveRiskCard', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              model_uuid: modelId,
              assessment_uuid: assessmentUuid,
              formData: {
                  [section]: sectionData
              }
          })
      });

      if (response.ok) {
          setIsSaving(false);
          setIsSaved(true);
          setTimeout(() => setIsSaved(false), 2000);
      } else {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Network response was not ok');
      }
  } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
  } finally {
      setIsSaving(false);
  }
}, 1500), [modelId, assessmentUuid]);


// const handleFieldChange = (section, category, fieldType, fieldName, event) => {
//   console.log(`Received event for section: ${section}, category: ${category}, field: ${fieldName}, type: ${fieldType}, value: ${event.target.value}`);
  
//   let value;
//   switch (fieldType) {
//       case 'text':
//       case 'dropdown':
//           value = event.target.value;
//           break;
//       case 'checkbox':
//           value = event.target.checked;
//           break;
//       case 'multiselect':
//           value = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
//           break;
//       default:
//           value = event.target.value;
//   }

//   console.log ()
//   // Update the main form data in Redux state
//   let updatedFormData = { ...riskFormData };

//   if (category) {
//       const categoryData = updatedFormData[section][category];

//       if (Array.isArray(categoryData)) {
//           // Handle the case where categoryData is an array
//           updatedFormData[section][category] = categoryData.map(field =>
//               field.name === fieldName
//                   ? { ...field, value }
//                   : field
//           );
//       } else if (typeof categoryData === 'object') {
//           // Handle the case where categoryData is an object
//           if (categoryData[fieldName]) {
//               categoryData[fieldName].value = value;
//           } else {
//               console.log(`Field "${fieldName}" does not exist under category "${category}".`);
//           }
//       }
//   } else {
//   //     // Handle updating a field directly under the section
//   //     updatedFormData[section] = updatedFormData[section].map(field =>
//   //         field.name === fieldName
//   //             ? { ...field, value }
//   //             : field
//   //     );
//   // }  } else {
//     // Handle updating a field directly under the section
//     const sectionData = updatedFormData[section];
//     const fieldIndex = sectionData.findIndex(field => field.name === fieldName);
//     if (fieldIndex !== -1) {
//       sectionData[fieldIndex] = { ...sectionData[fieldIndex], value };
//     }
//   }

//   // Dispatch the updated form data to Redux
//   dispatch(setFormData({
//       sectionName: section,
//       category,
//       fieldName,
//       value,
//   }, modelId));

//   // Save the updated section to the database
//   debouncedSave(section, updatedFormData[section]);
// };

const handleFieldChange = (section, category, fieldType, fieldName, event) => {
  console.log(`Received event for section: ${section}, category: ${category}, field: ${fieldName}, type: ${fieldType}, value: ${event.target.value}`);
  
  let value;
  switch (fieldType) {
      case 'text':
      case 'dropdown':
          value = event.target.value;
          break;
      case 'checkbox':
          value = event.target.checked;
          break;
      case 'multiselect':
          value = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
          break;
      default:
          value = event.target.value;
  }

  let updatedFormData = { ...riskFormData };

  if (category) {
      // Handle categorized fields
      const categoryData = updatedFormData[section][category];

      if (Array.isArray(categoryData)) {
          // If categoryData is an array
          updatedFormData[section][category] = categoryData.map(field =>
              field.name === fieldName
                  ? { ...field, value }
                  : field
          );
      } else if (typeof categoryData === 'object') {
          // If categoryData is an object
          if (categoryData[fieldName]) {
              categoryData[fieldName].value = value;
          } else {
              console.log(`Field "${fieldName}" does not exist under category "${category}".`);
          }
      }
  } else {
    // Handle non-categorized fields
    const sectionData = updatedFormData[section];
    console.log(`Handling non-categorized fields for section: ${section}`);
    console.log(`sectionData:`, sectionData);

    if (Array.isArray(sectionData)) {
        // If sectionData is an array, use findIndex to update
        console.log(`sectionData is an array. Looking for field: ${fieldName}`);
        const fieldIndex = sectionData.findIndex(field => field.name === fieldName);
        if (fieldIndex !== -1) {
            console.log(`Field found at index ${fieldIndex}. Updating value to:`, value);
            sectionData[fieldIndex] = { ...sectionData[fieldIndex], value };
        } else {
            console.log(`Field "${fieldName}" not found in array.`);
        }
    } else if (typeof sectionData === 'object') {
        // If sectionData is an object (like in the case of numbered keys)
        console.log(`sectionData is an object. Checking for field: ${fieldName}`);
        if (sectionData[fieldName]) {
            console.log(`Field "${fieldName}" exists. Updating value to:`, value);
            sectionData[fieldName].value = value;
        } else {
            console.log(`Field "${fieldName}" does not exist in section "${section}".`);
        }
    } else {
        console.log(`Unexpected structure for sectionData in section "${section}".`);
    }
}


  // Dispatch the updated form data to Redux
  dispatch(setFormData({
      sectionName: section,
      category,
      fieldName,
      value,
  }, modelId));

  // Save the updated section to the database
  debouncedSave(section, updatedFormData[section]);
};

useEffect(() => {
  // Reset the selected tab to the first one (riskCard) when the section changes
  setSelectedTab(0);
}, [sectionName]);

 

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            debouncedSave.flush();
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            debouncedSave.cancel();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [debouncedSave]);


    
    const handleStepChange = (tabName) => {

        setSelectedTab(tabName);
        dispatch (setStep(modelId,sectionName,tabName))
    };
    
    const progress = useMemo(() => {
      return (selectedTab + 1) / tabNames.length * 100;
  }, [selectedTab, tabNames.length]);

  useEffect(() => {
    // Dispatch progress to Redux whenever it changes
    dispatch(updateSectionProgress(modelId, sectionName, progress));
}, [dispatch, modelId, sectionName, progress]);

   

const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    const toTitleCase = (str) => {
        // First, handle known acronyms
        str = str.replace(/\b(EU|ROC|AUC)\b/g, match => match.toUpperCase());
      
        // Next, format camelCase and PascalCase
        return str
          .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each capital in camelCase
          .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Handle sequences of capitals followed by lowercase
          .replace(/\b([Aa]nd)\b/g, '&')
          .split(/\s+/) // Split the string by spaces to capitalize each word
          .map(word => {
            if (['EU', 'ROC', 'AUC'].includes(word)) {
              return word; // Return the acronym unchanged
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize each word
            }
          })
          .join(' ')
          .trim(); // Trim any extra spaces
      };
      
     
      

    const handleMouseEnter = (fieldName) => {
        setHoverStates(prev => ({ ...prev, [fieldName]: true }));
    };

    const handleMouseLeave = (fieldName) => {
        setHoverStates(prev => ({ ...prev, [fieldName]: false }));
    };


    const paperStyle = {
        padding: '20px',
        margin: '10px 0',
        boxShadow: '0px 3px 6px rgba(0,0,0,0.1)' // Soft shadow for depth
    };

      
      function renderField(section, category, field) {
        //console.log('Rendering field:', { section, category, field }); 
        
        switch (field.type) {
          case 'multiselect':
            return renderMultiselectField(section, category, field);
          case 'dropdown':
            return renderDropdownField(section,category, field);
          case 'text':
            return renderTextField(section,category, field);
            case 'select':  // Added handling for select type
            return renderDropdownField(section, category, field);
          default:
            return null;
        }
      }
      
      function renderMultiselectField(section,category, field) {
        return (
          <FormControl fullWidth margin="normal" variant="outlined" key={`${category}_${field.name}`}>
            <InputLabel>{toTitleCase(field.name)}</InputLabel>
            <Select
              multiple
              label={toTitleCase(field.name)}
              id={`${category}_${field.name}`}
              name={`${category}_${field.name}`}
              value={field.value || []}
              onChange={(e) => handleFieldChange(section,category, field.type, field.name, e)}
              renderValue={(selected) => (
                <div>{selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}</div>
              )}
              onMouseEnter={() => handleMouseEnter(field.name)}
              onMouseLeave={() => handleMouseLeave(field.name)}
              endAdornment={
                hoverStates[field.name] && (
                  <Tooltip title={field.description || 'No additional information available'}>
                    <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            >
              {field.options?.map((option, idx) => (
                <MenuItem key={idx} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      
      function renderDropdownField(section,category, field) {
        return (
          <FormControl fullWidth margin="normal" variant="outlined" key={`${category}_${field.name}`}>
            <InputLabel>{toTitleCase(field.name)}</InputLabel>
            <Select
              label={toTitleCase(field.name)}
              id={`${category}_${field.name}`}
              name={`${category}_${field.name}`}
              value={field.value || ''}
              onChange={(e) => handleFieldChange(section,category, field.type, field.name, e)}
              onMouseEnter={() => handleMouseEnter(field.name)}
              onMouseLeave={() => handleMouseLeave(field.name)}
              endAdornment={
                hoverStates[field.name] && (
                  <Tooltip title={field.description || 'No additional information available'}>
                    <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            >
              {field.options?.map((option, idx) => (
                <MenuItem key={idx} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      
      function renderTextField(section,category, field) {
        return (
          <FormControl fullWidth margin="normal" variant="outlined" key={`${category}_${field.name}`}>
            <TextField
              id={`${category}_${field.name}`}
              name={`${category}_${field.name}`}
              value={field.value || ''}
              label={toTitleCase(field.name)}
              onChange={(e) => handleFieldChange(section,category, field.type, field.name, e)}
              variant="outlined"
              multiline
              onMouseEnter={() => handleMouseEnter(field.name)}
              onMouseLeave={() => handleMouseLeave(field.name)}
              InputProps={{
                endAdornment: hoverStates[field.name] && (
                  <Tooltip title={field.description || 'No additional information available'}>
                    <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }}
            />
          </FormControl>
        );
      }

      const filterValidFields = (data, validKeys, sectionName) => {
        return _.pickBy(data, (value, key) => {
            return validKeys[sectionName].includes(value.name);
        });
    };

const isFlatArrayOfFields = (data, validKeys, sectionName) => {
  console.log("Checking if data is a flat array of fields:", data);

  if (_.isArray(data)) {
    const filteredData = _.filter(data, item => {
      return _.isObject(item) && _.has(item, 'name') && _.includes(validKeys[sectionName], item.name);
    });

    const isArrayOfFields = _.every(filteredData, item => {
      const hasName = _.has(item, 'name');
      console.log("Filtered item structure:", item, "Has name:", hasName);
      return hasName;
    });

    console.log("Is array of valid fields:", isArrayOfFields);
    return isArrayOfFields;
  }

  console.log("Data is not an array.");
  return false;
};



const renderFields = (section, fields, validKeys) => {
  console.log("Rendering fields for section:", section, "Fields:", fields, "Valid keys:", validKeys);

  if (_.isPlainObject(fields) && !_.isArray(fields)) {
    // Handle flat structure (like accuracy)
    return (
      <div>
        {_.map(fields, (field, key) => {
          if (_.isObject(field) && field.name && key !== 'assessmentProgress' && validKeys[section]?.includes(field.name)) {
            return (
              <div key={field.name || key}>
                {renderField(section, null, field)}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  } else {
    // Handle array structure (like other sections)
    return (
      <div>
        {_.map(
          _.filter(fields, field => {
            const isValid = _.includes(validKeys[section], field.name);
            console.log("Field:", field, "Is valid:", isValid);
            return isValid;
          }),
          (field, index) => (
            <div key={field.name || index}>
              {renderField(section, null, field)}
            </div>
          )
        )}
      </div>
    );
  }
};

const renderCategories = (section, categories, validKeys) => {
  console.log(`Rendering categories for section: ${section}`);

  return (
      <div>
          {Object.entries(categories)
          .filter(([categoryName]) => validKeys[section]?.includes(categoryName))
          .map(([categoryName, categoryContent]) => (
              <div key={categoryName}>
                  <Typography variant="h6" style={{ margin: "20px 0" }}>{toTitleCase(categoryName)}</Typography>
                  {Object.values(categoryContent)
                          .map((field, index) => (
                          <div key={field.name || index}>
                              {renderField(section, categoryName, field)}
                          </div>
                      ))}
              </div>
          ))}
      </div>
  );
};

const renderSectionContent = (sectionName, riskFormData, validKeys) => {
  const filteredData = filterValidFields(riskFormData[sectionName], validKeys, sectionName);

  if (_.isPlainObject(filteredData) && !_.isArray(filteredData) && !_.some(filteredData, _.isArray)) {
      return renderFields(sectionName, filteredData, validKeys);
  } else if (isFlatArrayOfFields(filteredData, validKeys, sectionName)) {
      return renderCategories(sectionName, filteredData, validKeys);
  } else {
      return renderCategories(sectionName, filteredData, validKeys);
  }
};

 

    return (
           
        <div>
            <Paper elevation={1} style={{ padding: '20px', marginBottom: '16px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                                        {/* Title and Loading Indicator */}
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1 }}>
                                            <Typography variant="h5">{toTitleCase(sectionName)} Risks</Typography>
                                            {isLoading && (
                                                <div style={{ position: 'relative', display: 'inline-flex' }}>
                                                    <CircularProgress
                                                        size={24}
                                                        thickness={5}
                                                        sx={{ color: 'primary.main' }}
                                                    />
                                                    <SmartToyOutlinedIcon
                                                        style={{
                                                            color: 'secondary.light',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {/* Autosave Indicator */}
                                        <div style={{ marginLeft: '10px' }}>
                                            <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
                                        </div>
                                    </div>
                                   
                                    <TabStepper
                                         
                                        labels={tabNames.map(name => toTitleCase(name))}
                                        activeTab={selectedTab}
                                        onStepChange={handleStepChange}
                                        style={{ padding: '0 10px' }} // Add padding around the TabStepper
                                    />
                                </Paper>
           
            <Grid container spacing={2}>

                   
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                   
                      <div>
                          


{riskFormData[sectionName] && (
            <Paper key={sectionName} style={paperStyle}>
              {/* {selectedTab === 0 && (
                <div>
                  {_.isPlainObject(riskFormData[sectionName]) && !_.isArray(riskFormData[sectionName]) && !_.some(riskFormData[sectionName], _.isArray) ? (
                    renderFields(sectionName, riskFormData[sectionName], validKeys)
                  ) : (
                    isFlatArrayOfFields(riskFormData[sectionName], validKeys, sectionName) ? (
                      renderCategories(sectionName, riskFormData[sectionName], validKeys)
                    ) : (
                      renderCategories(sectionName, riskFormData[sectionName], validKeys)
                    )
                  )}
                </div>
              )} */}
                         {selectedTab === 0 && (
                                <div>
                                    {renderSectionContent(sectionName, riskFormData, validKeys)}
                                </div>
                            )}

                    {selectedTab === 1 && (
                        <RiskCatalogue modelId={modelId} sectionName={sectionName}  assessmentUuid={assessmentUuid} narratives={narratives} />
                )}
                
                
                     {selectedTab === 2 && (
                        <RiskEvaluation modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName} />  
                    )}
                {selectedTab === 3 && (
                    <ActionPlan riskFormData={riskFormData} modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName}/>
                    )}
                    {selectedTab === 4  && (
                   < AssessmentCompleted  modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName}/>
                    )}
                        
                        </Paper>
                                 )}
                        </div>
                                                 
                        </Grid>    
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
        
    );
};

export default RiskCard;

