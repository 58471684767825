// src/redux/reducers/contextReducer.js

import { SET_CONTEXT_DATA, UPDATE_CONTEXT_DATA, SAVE_CONTEXT_DATA_FAILURE,  FETCH_NARRATIVE_SUCCESS, FETCH_CONTEXT_DATA_START, FETCH_CONTEXT_DATA_SUCCESS, FETCH_CONTEXT_DATA_FAILURE, FETCH_NARRATIVE_START} from '../actions/actionTypes';

const initialState = {
    data: {},
    narratives: {},
    loading: false,
    error: null
};

const contextReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT_DATA: {
            const { modelId, data } = action.payload;
            return {
              ...state,
              data: {
                ...state.data,
                [modelId]: data
              }
            };
          }
          
          case UPDATE_CONTEXT_DATA: {
            const { modelId, section, fieldName, value } = action.payload;
            console.log('Received in context data reducer for update', action.payload);
            // Ensure data structure integrity when updating nested fields
            const modelData = state.data[modelId] || {};
            const sectionData = modelData[section] || {};
            return {
              ...state,
              data: {
                ...state.data,
                [modelId]: {
                  ...modelData,
                  [section]: {
                    ...sectionData,
                    [fieldName]: value
                  }
                }
              }
            };
          }

        
        
        case SAVE_CONTEXT_DATA_FAILURE:
                        return {
                        ...state,
                        loading: false,
                        error: action.payload
                        };

        case FETCH_NARRATIVE_START:
            return {
                ...state,
                loading: true,
                error: null
            };

            case FETCH_NARRATIVE_SUCCESS: 
                            const { modelId, section, narrative } = action.payload;
                            const modelData = state.data[modelId] || {};
                            const sectionData = modelData[section] || {};
                            console.log ('narative received in reducer', action.payload)
                            return {
                              ...state,
                              data: {
                                ...state.data,
                                [modelId]: {
                                  ...modelData,
                                  [section]: {
                                    ...sectionData,
                                    narrative:narrative
                                  }
                                }
                               
                              },
                              loading: false,
                              error: null
                            };

          case FETCH_CONTEXT_DATA_START:
            return {
                ...state,
                loading: true,
                error: null
            };
       
            case FETCH_CONTEXT_DATA_SUCCESS: {
                const { modelId, data } = action.payload;
                
                const existingData = state.data[modelId] || {};
            
                return {
                    ...state,
                    loading: false,
                    data: {
                        ...state.data,
                        [modelId]: {
                            ...existingData,
                            ...data // Merge fetched data with existing data
                        }
                    },
                    
                    error: null
                };
            }
            
        case FETCH_CONTEXT_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
                   
                        default:
                        return state;
                        }
                    };

                        
                        
                        export default contextReducer;