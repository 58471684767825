// Form Data Actions

export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_STEP='SET_STEP';
export const SET_MODEL_ID = 'SET_MODEL_ID';
export const UPDATE_ASSESSMENT_STEP='UPDATE_ASSESSMENT_STEP'
export const UPDATE_ASSESSMENT_STEP_SUCCESS='UPDATE_ASSESSMENT_STEP_SUCCESS'
export const UPDATE_ASSESSMENT_STEP_FAILURE='UPDATE_ASSESSMENT_STEP_FAILURE'
export const INITIALIZE_MODEL='INITIALIZE_MODEL'
export const FETCH_MODELS_FAILURE='FETCH_MODELS_FAILURE'
export const FETCH_MODELS_START='FETCH_MODELS_START'
export const FETCH_MODELS_SUCCESS='FETCH_MODELS_SUCCESS'
// Section Actions
export const SET_SECTION = 'SET_SECTION';
export const UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS = 'UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS';

// Risk Actions
export const SET_RISKS = 'SET_RISKS';
export const TOGGLE_SELECTED_THEME = 'TOGGLE_SELECTED_THEME';
export const ADD_SELECTED_THEME = 'ADD_SELECTED_THEME';
export const REMOVE_SELECTED_THEME = 'REMOVE_SELECTED_THEME';
export const HANDLE_SELECTED_RISKS_UPDATE = 'HANDLE_SELECTED_RISKS_UPDATE';
export const SAVE_SELECTED_RISKS = 'SAVE_SELECTED_RISKS';
export const DISCARD_RISKS = 'DISCARD_RISKS';
export const UPDATE_RISKS_AFTER_SELECTION = 'UPDATE_RISKS_AFTER_SELECTION';
export const UPDATE_RISK_NAME='UPDATE_RISK_NAME';
export const SET_RISK_EVALUATION = 'SET_RISK_EVALUATION';
export const UPDATE_RISK_EVALUATION ='UPDATE_EVALUATION_DATA'
export const SET_RISK_PRIORITY='SET_RISK_PRIORITY'
export const UPDATE_RISK_PRIORITY='UPDATE_RISK_PRIORITY'
export const DISCARD_UNSELECTED_RISKS='DISCARD_UNSELECTED_RISKS'
export const UPDATE_CONTROL_IN_SELECTED_RISK='UPDATE_CONTROL_IN_SELECTED_RISK'
export const FETCH_RISK_CARD_DATA_START = 'FETCH_RISK_CARD_DATA_START';
export const FETCH_RISK_CARD_DATA_SUCCESS = 'FETCH_RISK_CARD_DATA_SUCCESS';
export const FETCH_RISK_CARD_DATA_FAILURE = 'FETCH_RISK_CARD_DATA_FAILURE';
export const MOVE_RISK_TO_SAVED = 'MOVE_RISK_TO_SAVED';
export const UPDATE_SAVED_RISK = 'UPDATE_SAVED_RISK';
export const DISCARD_SAVED_RISK='DISCARD_SAVED_RISK';
export const REFRESH_RISKS_SUCCESS = 'REFRESH_RISKS_SUCCESS';
export const FETCH_MODEL_RISKS_START='FETCH_MODEL_RISKS_START'
export const FETCH_MODEL_RISKS_SUCCESS='FETCH_MODEL_RISKS_SUCCESS'
export const FETCH_MODEL_RISKS_FAILURE='FETCH_MODEL_RISKS_FAILURE'
export const SET_RISK_PRIORITY_START='SET_RISK_PRIORITY_START'
export const SET_RISK_PRIORITY_SUCCESS='SET_RISK_PRIORITY_SUCCESS'
export const SET_RISK_PRIORITY_FAILURE='SET_RISK_PRIORITY_FAILURE'



//UTILITY FUNCTIONS
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_ASSESSMENT_STATUS = 'UPDATE_ASSESSMENT_STATUS';
export const ASSESSMENT_STATUS_UPDATED = 'ASSESSMENT_STATUS_UPDATED';
export const ASSESSMENT_STATUS_FAILED = 'ASSESSMENT_STATUS_FAILED';
export const UPDATE_LOADING_MESSAGE='UPDATE_LOADING_MESSAGE'
export const UPDATE_SECTION_PROGRESS = 'UPDATE_SECTION_PROGRESS';

// Fetch Actions
export const FETCH_RISKS_START = 'FETCH_RISKS_START';
export const FETCH_RISKS_SUCCESS = 'FETCH_RISKS_SUCCESS';
export const FETCH_RISKS_FAILURE = 'FETCH_RISKS_FAILURE';
export const FETCH_RISKS_FROM_LLM = 'FETCH_RISKS_FROM_LLM';
export const FETCH_SECTION_SPECIFIC_RISKS_START='FETCH_SECTION_SPECIFIC_RISKS_START';
export const FETCH_SECTION_SPECIFIC_RISKS_SUCCESS='FETCH_SECTION_SPECIFIC_RISKS_SUCCESS';
export const FETCH_SECTION_SPECIFIC_RISKS_FAILURE = 'FETCH_SECTION_SPECIFIC_RISKS_FAILURE';
export const FETCH_RISK_EVALUATIONS='FETCH_RISK_EVALUATIONS'
export const FETCH_RISK_EVALUATIONS_SUCCESS='FETCH_RISK_EVALUATIONS_SUCCESS'
export const FETCH_RISK_EVALUATIONS_FAILURE='FETCH_RISK_EVALUATIONS_FAILURE'


export const RESET_RISKS_STATE = 'RESET_RISKS_STATE'
//Security Actions
export const LOAD_SECURITY_ASSESSMENT = 'LOAD_SECURITY_ASSESSMENT'
export const UPDATE_SECURITY_ASSESSMENT = 'UPDATE_SECURITY_ASSESSMENT';
export const SAVE_SECURITY_ASSESSMENT = 'SAVE_SECURITY_ASSESSMENT';
export const SAVE_SECURITY_ASSESSMENT_SUCCESS = 'SAVE_SECURITY_ASSESSMENT_SUCCESS';
export const SAVE_SECURITY_ASSESSMENT_FAILURE = 'SAVE_SECURITY_ASSESSMENT_FAILURE';
export const GENERATE_SECURITY_RISKS_START = 'GENERATE_SECURITY_RISKS_START';
export const GENERATE_SECURITY_RISKS_SUCCESS = 'GENERATE_SECURITY_RISKS_SUCCESS';
export const GENERATE_SECURITY_RISKS_FAILURE = 'GENERATE_SECURITY_RISKS_FAILURE';
export const SET_ACTIVE_IDS = 'SET_ACTIVE_IDS';
export const FETCH_SECURITY_ASSESSMENT_START = 'FETCH_SECURITY_ASSESSMENT_START';
export const FETCH_SECURITY_ASSESSMENT_SUCCESS = 'FETCH_SECURITY_ASSESSMENT_SUCCESS';
export const FETCH_SECURITY_ASSESSMENT_FAILURE = 'FETCH_SECURITY_ASSESSMENT_FAILURE';
export const RESET_SECURITY_STATE = 'RESET_SECURITY_STATE'
export const SAVE_SECURITY_RISK_START = 'SAVE_RISK_START';
export const SAVE_SECURITY_RISK_SUCCESS = 'SAVE_RISK_SUCCESS';
export const SAVE_SECURITY_RISK_FAILURE = 'SAVE_RISK_FAILURE';
export const FETCH_SECURITY_RISKS_START = 'FETCH_SECURITY_RISKS_START';
export const FETCH_SECURITY_RISKS_SUCCESS = 'FETCH_SECURITY_RISKS_SUCCESS';
export const FETCH_SECURITY_RISKS_FAILURE = 'FETCH_SECURITY_RISKS_FAILURE';
export const DISCARD_SECURITY_RISK_START = 'DISCARD_SECURITY_RISK_START';
export const DISCARD_SECURITY_RISK_SUCCESS = 'DISCARD_SECURITY_RISK_SUCCESS';
export const DISCARD_SECURITY_RISK_FAILURE = 'DISCARD_SECURITY_RISK_FAILURE';
export const FETCH_SECURITY_CONTROLS_START = 'FETCH_SECURITY_CONTROLS_START';
export const FETCH_SECURITY_CONTROLS_SUCCESS = 'FETCH_SECURITY_CONTROLS_SUCCESS';
export const FETCH_SECURITY_CONTROLS_FAILURE = 'FETCH_SECURITY_CONTROLS_FAILURE';
export const SAVE_SECURITY_CONTROL_START = 'SAVE_SECURITY_CONTROL_START';
export const SAVE_SECURITY_CONTROL_SUCCESS = 'SAVE_SECURITY_CONTROL_SUCCESS';
export const SAVE_SECURITY_CONTROL_FAILURE = 'SAVE_SECURITY_CONTROL_FAILURE';



//Context Actions

export const SET_CONTEXT_DATA = 'SET_CONTEXT_DATA';
export const UPDATE_CONTEXT_DATA = 'UPDATE_CONTEXT_DATA';
export const SAVE_CONTEXT_DATA_START='SAVE_CONTEXT_DATA_START'
export const SAVE_CONTEXT_DATA_SUCCESS = 'SAVE_CONTEXT_DATA_SUCCESS';
export const SAVE_CONTEXT_DATA_FAILURE = 'SAVE_CONTEXT_DATA_FAILURE';
export const FETCH_NARRATIVE_START = 'FETCH_NARRATIVE_START';
export const FETCH_NARRATIVE_SUCCESS = 'FETCH_NARRATIVE_SUCCESS';
export const FETCH_NARRATIVE_FAILURE = 'FETCH_NARRATIVE_FAILURE';
export const FETCH_CONTEXT_DATA_START = 'FETCH_CONTEXT_DATA_START';
export const FETCH_CONTEXT_DATA_SUCCESS = 'FETCH_CONTEXT_DATA_SUCCESS';
export const FETCH_CONTEXT_DATA_FAILURE = 'FETCH_CONTEXT_DATA_FAILURE';
export const GENERATE_SECURITY_CONTROLS_START='GENERATE_SECURITY_CONTROLS_START'
export const GENERATE_SECURITY_CONTROLS_SUCCESS='GENERATE_SECURITY_CONTROLS_SUCCESS'
export const GENERATE_SECURITY_CONTROLS_FAILURE='GENERATE_SECURITY_CONTROLS_FAILURE'
