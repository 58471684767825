import controlReducer from './controlReducer.js';
import { combineReducers } from 'redux';
import themeReducer from './themeReducer.js';
import riskReducer from './riskReducer.js';
import securityReducer from './securityReducer.js';
import contextReducer from './contextReducer.js';
//import authReducer from './authReducer';

const rootReducer = combineReducers({
    themes: themeReducer,
    risks: riskReducer,
    controls: controlReducer, 
    security:securityReducer,
    context: contextReducer,
    //auth: authReducer
});

export default rootReducer;
