//   import React, { useState, useEffect} from 'react';
// import { useSelector, useDispatch } from 'react-redux';
//   import ModelCardForm from './ModelCardForm.js';
//   import RiskCardForm from './RiskCardForm.js';
//   import RiskCatalogue from './RiskCatalogue.js';
//   import RiskEvaluation from './RiskEvaluation.js';
//   import RiskLandscape from './RiskLandscape.js';
//   import ModelInventory from './ModelInventory.js';
//   import ModelDetailsModal from './ModelDetailsModal.js';
//   import AssessmentCompleted from './AssessmentCompleted.js';
//   import RiskFormManager from './riskFormManager'; // Adjust path if necessary
//   import { Route, Routes, useNavigate} from 'react-router-dom';

//   import { AppBar, Toolbar, Typography, IconButton, Box, Grid, Hidden, Avatar } from '@mui/material';
// import AccountCircle from '@mui/icons-material/AccountCircle';
//   import Settings from '@mui/icons-material/Settings';
//   import MenuIcon from '@mui/icons-material/Menu';
//   import { styled } from '@mui/material/styles';
//   import RiskThemes from './RiskThemes.js';
//   import Audits from './Audits.js';
//   import SideMenu from './SideMenu';
//   import Logo from './images/logomain.png'; 
//   import { Snackbar, Alert } from '@mui/material';

//   import ContextAssessment from './contextAssessment.js';
// //Auth
// import { useAuth0 } from '@auth0/auth0-react';



//   function App() {
//     const { user, isAuthenticated, isLoading, logout } = useAuth0();
//     const [riskCardData, setRiskCardData] = useState(null);
//     const [selectedModel, setSelectedModel] = useState(null);
//   const user = useSelector((state) => state.auth.user);
//   const dispatch=useDispatch(); 
//     const navigate = useNavigate();
//     const [selectedModelId, setSelectedModelId] = useState(null);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Could be 'error', 'warning', etc.
//     const handleSnackbarClose = () => {
//               setSnackbarOpen(false);
//           };

        
//           useEffect(() => {
//             if (!isAuthenticated && !isLoading) {
//               logout({ returnTo: window.location.origin });
//             }
//           }, [isAuthenticated, isLoading, logout]);

//     const handleFormSubmit = async (formData, formType, modelId) => {
//       console.log("Submitting data:", formData);
      
//       let response;
//       try {
//         if (formType === 'create') {
//           console.log('entering create');
//           response=await fetch('/.netlify/functions/SaveModelCard', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(formData),
//           });
//         } else if (formType === 'update'&& modelId) {
//           console.log('model ID inside update condition',modelId);
//           console.log('entering update');
//           response=await fetch(`/.netlify/functions/SaveModelCard/${modelId}`, { // Change to your update endpoint
//             method: 'PUT', 
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(formData),
//           });
//         } else if (formType === 'risk') {
//           setRiskCardData(formData); 
//           const payload = {
//             ...formData,
//             modelDescription: {
//               ...formData.modelDescription,
//               model_uuid: modelId,
//             },
//           };
//           response=await fetch('/.netlify/functions/SaveRiskCard', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload),
//           });
//         }
//         const responseBody = await response.json();

//         if (responseBody.success) {
//           setSnackbarMessage(responseBody.message);
//           setSnackbarSeverity('success');
//         } else {
//           setSnackbarMessage(responseBody.message);
//           setSnackbarSeverity('error');
//         }
//       } catch (error) {
//         setSnackbarMessage(`An error occurred: ${error.message}`);
//         setSnackbarSeverity('error');
//       }
//       setSnackbarOpen(true);
//     };
    

//     const handleRiskEvaluate = async (risk, evaluationData) => {
//     console.log('Evaluating risk:', risk, 'with data:', evaluationData);
//     };


//     const handleSelectModel = (model) => {
//       console.log('Model:', model);
//       setSelectedModel(model);
//       setSelectedModelId(model.model_uuid);
      
//       console.log('Selected model:', selectedModel);
//       console.log('Selected model ID:', selectedModelId);
//     };



//     const handleBackToInventory = () => {
//       setSelectedModel(null);
//       setRiskCardData(null);
      
//     };
//     const SettingsButton = () => {
//       This is correct because `SettingsButton` will be rendered within Router
  
//       return (
//         <IconButton color="#f1cf94" onClick={() => navigate('/risk-form-manager')}>
//           <Settings />
//         </IconButton>
//       );
//     };
  
//   ethereal ivory #E4E4DE, sophisticated sage #C4C5BA, timeless noir #cacacf, chatgpt noir #1f2123, muted moss #595f39
//     const TopAppBar = styled(AppBar)({
//       background: '#fbfbfe', 
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
//     });
    
    
//     const SideBar = styled(Box)({
//       flexGrow: 1,
//       backgroundColor: '#42424a', 
//     pt: '300px', // Adjusted to AppBar height
//       pr: 1,
//     paddingLeft: '10px', // Add this
//       minHeight: 'calc(100vh - 60px)',
//       position: 'sticky', 
//       top: 0,
//       overflow: 'auto',
//       color: '#cacacf',  
//     });
//     const PageTitle = styled(Typography)({
//       fontSize: '1.3rem',
//       fontWeight: '600',
//       color: '#1f2123', light gray
//       marginLeft: '10px' // Aligns the title to the left
//     });

   
//     return (
//       <>
  
//       <TopAppBar position="sticky" sx={{ height: '60px' }}>
//           <Toolbar style={{
//           padding: '10px',
//             minHeight: '60px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between'
//           }}>
//             <Hidden smUp>
//               <IconButton edge="start" color="inherit" aria-label="menu">
//                 <MenuIcon />
//               </IconButton>
//             </Hidden>
//             <Box display="flex" alignItems="center">
//               <img src={Logo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
//               <PageTitle>
//                 RiskGPT
//               </PageTitle>
//             </Box>
//             <Box display="flex" alignItems="center">
//             {isAuthenticated && user && (
//               <>
//                 <Typography variant="h6" style={{ marginRight: '10px' }}>
//                   {user.name}
//                 </Typography>
//                 <Avatar src={user.picture} alt={user.name} />
//                 <IconButton color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>
//                   <Typography variant="body1" style={{ marginLeft: '10px', color: '#f1cf94' }}>
//                     Logout
//                   </Typography>
//                 </IconButton>
//               </>
//             )}
//             <SettingsButton />
//           </Box>
//           </Toolbar>
//         </TopAppBar>
//         <Grid container>
//           <Grid item xs={12} sm={3} md={2}>

//             <SideBar component="sidebar">
//             <SideMenu />
//             </SideBar>
//           </Grid>
//           <Grid item xs={12} sm={9} md={10}>
//             <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#FAFAFA' }}>
              
             
//             <Routes>
            
//             <Route path="/model-form" element={<ModelCardForm onSubmit={(formData) => handleFormSubmit(formData, 'create')} />} />
//             <Route path="/model-form/update/:modelId" element={<ModelCardForm onSubmit={(formData, modelId) => handleFormSubmit(formData, 'update', modelId)} onBackToInventory={handleBackToInventory} />} />
//             <Route path="/model-details/:modelId" element={<ModelDetailsModal />} /> 
//             <Route path="/risk-themes" element={<RiskThemes />} />
//             <Route path="/risk-landscape" element={<RiskLandscape />} />
//             <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
//             <Route path="/risk-form-manager" element={<RiskFormManager />} />
//             <Route path="/risk-themes/:modelId" element={<RiskThemes />} /> 
//             <Route path="/context-evaluation" element={<ContextAssessment />} /> 
//             <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm onSubmit={handleFormSubmit} onBackToInventory={handleBackToInventory} selectedModel={selectedModel} selectedModelId={selectedModelId}/>} />
//               <Route path="/" element={<ModelInventory onSelectModel={(model) => handleSelectModel(model)} />} /> 
//               {/* <Route path="/" element={user ? <ModelInventory onSelectModel={(model) => handleSelectModel(model)}/> : <Navigate to="/auth" />} /> */}
//               <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue riskCardData={riskCardData} onBackToInventory={handleBackToInventory} />} />
//               <Route path="/risk-evaluation" element={<RiskEvaluation onRiskEvaluate={handleRiskEvaluate} modelId={selectedModelId} />} />
//               <Route path="/audits" element={<Audits />} /> 
//               <Route path="*" element={<div>Not Found</div>} />
//             </Routes>
          
//             <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>

//           </Box>
        
//       </Grid>
//     </Grid>
    
//    {/* </Router>
//  </PersistGate>
//   </Provider> */}
//    </>
//   );
  
//   }
//   export default App
// import React, { useState, useEffect } from 'react';
// import { Route, Routes, useNavigate } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Button, Menu, MenuItem } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Settings from '@mui/icons-material/Settings';
// import ModelCardForm from './ModelCardForm.js';
// import RiskCardForm from './RiskCardForm.js';
// import RiskCatalogue from './RiskCatalogue.js';
// import RiskEvaluation from './RiskEvaluation.js';
// import RiskLandscape from './RiskLandscape.js';
// import ModelInventory from './ModelInventory.js';
// import ModelDetailsModal from './ModelDetailsModal.js';
// import AssessmentCompleted from './AssessmentCompleted.js';
// import RiskFormManager from './riskFormManager.js';
// import RiskThemes from './RiskThemes.js';
// import ContextAssessment from './contextAssessment.js';
// import Logo from './images/logomain.png';
// import { Snackbar, Alert } from '@mui/material';
// import { useAuth0 } from '@auth0/auth0-react';

// const TopAppBar = styled(AppBar)({
//   background: '#fbfbfe',
//   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
// });

// const PageTitle = styled(Typography)({
//   fontSize: '1.3rem',
//   fontWeight: '600',
//   color: '#1f2123',
//   marginLeft: '10px',
// });

// function App() {
//   const { user, isAuthenticated, isLoading, logout } = useAuth0();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [selectedModel, setSelectedModel] = useState(null);
//   const [selectedModelId, setSelectedModelId] = useState(null);
  
//   const navigate = useNavigate();

//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   useEffect(() => {
//     if (!isAuthenticated && !isLoading) {
//       logout({ returnTo: window.location.origin });
//     }
//   }, [isAuthenticated, isLoading, logout]);

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//       const handleSelectModel = (model) => {
//       console.log('Model:', model);
//       setSelectedModel(model);
//       setSelectedModelId(model.model_uuid);
      
//       console.log('Selected model:', selectedModel);
//       console.log('Selected model ID:', selectedModelId);
//     };



//   const handleFormSubmit = async (formData, formType, modelId) => {
//     let response;
//     try {
//       if (formType === 'create') {
//         response = await fetch('/.netlify/functions/SaveModelCard', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(formData),
//         });
//       } else if (formType === 'update' && modelId) {
//         response = await fetch(`/.netlify/functions/SaveModelCard/${modelId}`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(formData),
//         });
//       } else if (formType === 'risk') {
//         const payload = {
//           ...formData,
//           modelDescription: {
//             ...formData.modelDescription,
//             model_uuid: modelId,
//           },
//         };
//         response = await fetch('/.netlify/functions/SaveRiskCard', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });
//       }
//       const responseBody = await response.json();
//       if (responseBody.success) {
//         setSnackbarMessage(responseBody.message);
//         setSnackbarSeverity('success');
//       } else {
//         setSnackbarMessage(responseBody.message);
//         setSnackbarSeverity('error');
//       }
//     } catch (error) {
//       setSnackbarMessage(`An error occurred: ${error.message}`);
//       setSnackbarSeverity('error');
//     }
//     setSnackbarOpen(true);
//   };

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <TopAppBar position="sticky" sx={{ height: '60px' }}>
//         <Toolbar
//           style={{
//             minHeight: '60px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Box display="flex" alignItems="center">
//             <img src={Logo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
//             <PageTitle>RiskGPT</PageTitle>
//           </Box>
//           <Box display="flex" alignItems="center">
//             <Button color="inherit" onClick={() => navigate('/')}>
//               Model Inventory
//             </Button>
//             <Button color="inherit" onClick={() => navigate('/risk-reports')}>
//               Risk Reports
//             </Button>
//             <IconButton color="inherit" onClick={handleMenuClick}>
//               <Settings />
//             </IconButton>
//             <Menu
//               anchorEl={anchorEl}
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem onClick={() => { navigate('/risk-themes'); handleMenuClose(); }}>Risk Themes</MenuItem>
//               <MenuItem onClick={() => { navigate('/risk-form-manager'); handleMenuClose(); }}>Risk Form Manager</MenuItem>
//             </Menu>
//             {isAuthenticated && user && (
//               <>
//                 <Typography variant="h6" style={{ marginRight: '10px' }}>
//                   {user.name}
//                 </Typography>
//                 <Avatar src={user.picture} alt={user.name} />
//                 <IconButton color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>
//                   Logout
//                 </IconButton>
//               </>
//             )}
//           </Box>
//         </Toolbar>
//       </TopAppBar>

//       <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#FAFAFA' }}>
//         {/* <Routes>
//           <Route path="/" element={<ModelInventory />} />
//           <Route path="/model-form" element={<ModelCardForm onSubmit={handleFormSubmit} />} />
//           <Route path="/model-form/update/:modelId" element={<ModelCardForm onSubmit={handleFormSubmit} />} />
//           <Route path="/model-details/:modelId" element={<ModelDetailsModal />} />
//           <Route path="/risk-reports" element={<RiskLandscape />} />
//           <Route path="/risk-themes" element={<RiskThemes />} />
//           <Route path="/risk-form-manager" element={<RiskFormManager />} />
//           <Route path="/context-evaluation" element={<ContextAssessment />} />
//           <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm />} />
//           <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue />} />
//           <Route path="/risk-evaluation" element={<RiskEvaluation />} />
//           <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
//           <Route path="*" element={<div>Not Found</div>} />
//         </Routes> */}
// <Routes>
            
//                        <Route path="/model-form" element={<ModelCardForm onSubmit={(formData) => handleFormSubmit(formData, 'create')} />} />
//                        <Route path="/model-form/update/:modelId" element={<ModelCardForm onSubmit={(formData, modelId) => handleFormSubmit(formData, 'update', modelId)}  />} />
//                        <Route path="/model-details/:modelId" element={<ModelDetailsModal />} /> 
//                        <Route path="/risk-themes" element={<RiskThemes />} />
//                        <Route path="/risk-landscape" element={<RiskLandscape />} />
//                        <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
//                        <Route path="/risk-form-manager" element={<RiskFormManager />} />
//                        <Route path="/risk-themes/:modelId" element={<RiskThemes />} /> 
//                        <Route path="/context-evaluation" element={<ContextAssessment />} /> 
//                        <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm  selectedModel={selectedModel} selectedModelId={selectedModelId}/>} />
//                         <Route path="/" element={<ModelInventory onSelectModel={(model) => handleSelectModel(model)} />} /> 
//                         <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue   />} />
//                          <Route path="/risk-evaluation" element={<RiskEvaluation modelId={selectedModelId} />} />
//                           <Route path="*" element={<div>Not Found</div>} />
//                        </Routes>
                      
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//         >
//           <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </>
//   );
// }

// export default App;
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Button, Menu, MenuItem } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import Settings from '@mui/icons-material/Settings';
import ModelCardForm from './ModelCardForm.js';
import RiskCardForm from './RiskCardForm.js';
import RiskCatalogue from './RiskCatalogue.js';
import RiskEvaluation from './RiskEvaluation.js';
import RiskLandscape from './RiskLandscape.js';
import ModelInventory from './ModelInventory.js';
import ModelDetailsModal from './ModelDetailsModal.js';
import AssessmentCompleted from './AssessmentCompleted.js';
import RiskFormManager from './riskFormManager.js';
import RiskThemes from './RiskThemes.js';
import ContextAssessment from './contextAssessment.js';
import Logo from './images/logomain.png';
import { Snackbar, Alert } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import IconWithProgress from './IconWithProgress.js';
import FileUpload from './fileUploader.js';

const TopAppBar = styled(AppBar)({
  background: '#fbfbfe',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
});

const PageTitle = styled(Typography)({
  fontSize: '1.3rem',
  fontWeight: '600',
  color: '#1f2123',
  marginLeft: '10px',
});

const MenuButton = styled(Button)({
  color: '#42424a', // Darker color for visibility
  fontWeight: '500',
  marginLeft: '50px', // Add some space between logo and menu items
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoadingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh', // Full viewport height
  backgroundColor: '#f7f9fc',
  animation: `${fadeIn} 1s ease-in-out`,
});

const LoadingText = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: '500',
  marginLeft: '15px',
  color: '#42424a',
  animation: `${fadeIn} 2s ease-in-out`,
});

function App() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);

  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      logout({ returnTo: window.location.origin });
    }
  }, [isAuthenticated, isLoading, logout]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSelectModel = (model) => {
    setSelectedModel(model);
    setSelectedModelId(model.model_uuid);
  };


      
      console.log('Selected model:', selectedModel);
      console.log('Selected model ID:', selectedModelId);
    

  const handleFormSubmit = async (formData, formType, modelId) => {
    let response;
    try {
      if (formType === 'create') {
        response = await fetch('/.netlify/functions/SaveModelCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      } else if (formType === 'update' && modelId) {
        response = await fetch(`/.netlify/functions/SaveModelCard/${modelId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      } else if (formType === 'risk') {
        const payload = {
          ...formData,
          modelDescription: {
            ...formData.modelDescription,
            model_uuid: modelId,
          },
        };
        response = await fetch('/.netlify/functions/SaveRiskCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
      }
      const responseBody = await response.json();
      if (responseBody.success) {
        setSnackbarMessage(responseBody.message);
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(responseBody.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage(`An error occurred: ${error.message}`);
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <IconWithProgress isLoading={isLoading}/>
        <LoadingText>Loading RiskGPT...</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <>

       <TopAppBar position="sticky" sx={{ height: '60px' }}>
        <Toolbar
          style={{
            minHeight: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center">
            <img src={Logo} alt="logo" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            <PageTitle>RiskGPT</PageTitle>
            <MenuButton onClick={() => navigate('/')}>
              Model Inventory
            </MenuButton>
            <MenuButton onClick={() => navigate('/risk-reports')}>
              Risk Reports
            </MenuButton>
          </Box>
          {isAuthenticated && user && (
            <Box display="flex" alignItems="center">
              <MenuButton onClick={handleMenuClick}>
                <Settings />
              </MenuButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => { navigate('/risk-themes'); handleMenuClose(); }}>Risk Themes</MenuItem>
                <MenuItem onClick={() => { navigate('/risk-form-manager'); handleMenuClose(); }}>Risk Form Manager</MenuItem>
                <MenuItem onClick={() => { navigate('/file-upload'); handleMenuClose(); }}>Risk & Control Catalogues</MenuItem>

              </Menu>
              <Typography variant="h6" style={{color: '#42424a', marginRight: '10px' }}>
                Hello, {user.name || 'User'}
              </Typography>
              <IconButton onClick={handleUserMenuClick}>
                <Avatar src={user.picture} alt={user.name} />
              </IconButton>
              <Menu
                anchorEl={userMenuAnchorEl}
                open={Boolean(userMenuAnchorEl)}
                onClose={handleUserMenuClose}
              >
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </TopAppBar>

      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#FAFAFA' }}>
        <Routes>
          <Route path="/" element={<ModelInventory onSelectModel={handleSelectModel} />} />
          <Route path="/model-form" element={<ModelCardForm onSubmit={handleFormSubmit} />} />
          <Route path="/model-form/update/:modelId" element={<ModelCardForm onSubmit={handleFormSubmit} />} />
          <Route path="/model-details/:modelId" element={<ModelDetailsModal />} />
          <Route path="/risk-reports" element={<RiskLandscape />} />
          <Route path="/risk-themes" element={<RiskThemes />} />
          <Route path="/risk-form-manager" element={<RiskFormManager />} />
          <Route path="/context-evaluation" element={<ContextAssessment />} />
          <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm selectedModel={selectedModel} selectedModelId={selectedModelId}/>} />
          <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue />} />
          <Route path="/risk-evaluation" element={<RiskEvaluation />} />
          <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
          <Route path="/file-upload" element={<FileUpload />} />
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default App;
