
import { 
  INITIALIZE_MODEL,FETCH_MODEL_RISKS_SUCCESS,SET_FORM_DATA, SET_SECTION, SET_STEP, UPDATE_ASSESSMENT_STEP_SUCCESS,UPDATE_ASSESSMENT_STEP_FAILURE, SET_MODEL_ID,
    DISCARD_RISKS, DISCARD_UNSELECTED_RISKS,UPDATE_RISK_NAME,FETCH_RISKS_START, 
   FETCH_RISKS_FAILURE, SET_RISK_EVALUATION,UPDATE_RISK_PRIORITY,
   UPDATE_RISK_EVALUATION, UPDATE_RISKS_AFTER_SELECTION, UPDATE_CONTROL_IN_SELECTED_RISK, FETCH_RISK_CARD_DATA_START, 
   FETCH_RISK_CARD_DATA_SUCCESS, FETCH_RISK_CARD_DATA_FAILURE, FETCH_RISK_EVALUATIONS, FETCH_RISK_EVALUATIONS_SUCCESS,
  FETCH_RISK_EVALUATIONS_FAILURE,UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS, MOVE_RISK_TO_SAVED, UPDATE_SAVED_RISK,DISCARD_SAVED_RISK, RESET_RISKS_STATE,  FETCH_SECTION_SPECIFIC_RISKS_START,
  FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
  FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
  ASSESSMENT_STATUS_UPDATED,
  ASSESSMENT_STATUS_FAILED,
  REFRESH_RISKS_SUCCESS, SET_RISK_PRIORITY_START,
  SET_RISK_PRIORITY_SUCCESS,SET_RISK_PRIORITY_FAILURE, UPDATE_LOADING_MESSAGE, UPDATE_SECTION_PROGRESS
} from '../actions/actionTypes.js';
import riskForm from '../../riskForm.json';
import evaluationForm from '../../evaluationForm.json';
import _ from 'lodash';


const initialState = {
    models: {},
}

const initializeModel = (modelId, modelName,assessmentUuid,assessmentStatus,state) => {
  if (modelId && !state.models[modelId]) {
   const evaluationCriteria = JSON.parse(JSON.stringify(evaluationForm));
   //const initializedRiskForm = JSON.parse(JSON.stringify(riskForm));
   const initializedRiskForm = _.cloneDeep(riskForm);

    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: {
          modelName,
          assessmentUuid,
          assessmentStatus,
          riskFormData: initializedRiskForm,
          activeSections: [],
          sectionLoading: {},
          selectedRisks: [],
          risks: [],
          selectedThemes: [],
          discardedRisks: [],
          savedRisks: state.models[modelId]?.savedRisks?.map(risk => {
            return {
              ...risk,
              evaluationData: risk.evaluationData || evaluationCriteria,
              selectedControls: risk.selectedControls || []
              
            };
          }) || [],
          
          loading: false,
          error: null,
          step: ''
        },
      },
    };
  }
  return state;
}

const riskReducer = (state = initialState, action) => {
  if (!action.payload) {
    return state;
  }
  
const modelId = action.payload.modelId || null;
const modelName = action.payload ? action.payload.modelName || null : null;
const assessmentUuid=action.payload.assessmentUuid || null;
const assessmentStatus=action.payload.assessmentStatus || null;

  let newState = { ...state };
  //console.log('action payload in reducer', action.payload);
    if (modelId && !newState.models[modelId]) {
    newState = initializeModel(modelId,modelName, assessmentUuid,assessmentStatus,newState);
  }


  switch (action.type) {

   

    case RESET_RISKS_STATE:
      return initialState;

      case INITIALIZE_MODEL: {
        const { modelId, modelName, assessmentUuid, assessmentStatus } = action.payload;
        if (!state.models[modelId]) { 
            return initializeModel(modelId, modelName,assessmentUuid,assessmentStatus, state);
        }
        return state; // Return the current state if the model already exists
    }
    
    case SET_MODEL_ID: {
      const modelId = action.payload.modelId || null;
      if (modelId && !newState.models[modelId]) {
        newState = initializeModel(modelId, modelName, newState);
      }
      return newState;
    }

    case UPDATE_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload
      };

    

    case SET_SECTION: {
      const { modelId, sectionName } = action.payload;
      const model = state.models[modelId] 

      //console.log ('received at set section reducer:', action.payload)
    
      // Ensure the section exists within the sections object
      const section = model.activeSections[sectionName];

     // console.log ('updating section in set section reducer:', section)
    
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...model,
            currentSection: sectionName,
            activeSections: {
              ...model.activeSections,
              [sectionName]: {
                ...section
                
              }
            }
          }
        }
      };
    }
    

  
    // case SET_FORM_DATA: {
    //   const { modelId, sectionName, fieldName, value, category, description } = action.payload;

    //   console.log ('payload in set form data reducer', action.payload)
    
    //   if (!state.models[modelId]) {
    //     console.error('Model not found', modelId);
    //     return state;
    //   }
    
    //   const model = { ...state.models[modelId] };
    //   const section = model.riskFormData[sectionName];
    
    //   if (!section) {
    //     console.error('Section not found', sectionName);
    //     return state;
    //   }
    
    //   if (category) {
    //     // When a category is present, handle nested fields
    //     if (!section[category]) {
    //       console.error('Category not found', category);
    //       return state;
    //     }
    
    //     const fieldKey = Object.keys(section[category]).find(key => section[category][key].name === fieldName);
    //     if (!fieldKey) {
    //       console.error('Field not found in category', fieldName);
    //       return state;
    //     }
    
    //     // Update the nested field in the category
    //     const updatedField = {
    //       ...section[category][fieldKey],
    //       value: (section[category][fieldKey].type === 'multiselect' && !Array.isArray(value)) ? [value] : value,
    //       description: description || section[category][fieldKey].description
    //     };

    //     console.log('updated field in set form data reducer', updatedField)
    
    //     const updatedCategory = {
    //       ...section[category],
    //       [fieldKey]: updatedField
    //     };
    //     console.log('updated category in set form data reducer', updatedCategory)

    
    //     const updatedSectionWithCategory = {
    //       ...section,
    //       [category]: updatedCategory
    //     };
    //     console.log('updated category and field in set form data reducer', updatedSectionWithCategory)
    //     // Update the model with the new section data including the category
    //     return {
    //       ...state,
    //       models: {
    //         ...state.models,
    //         [modelId]: {
    //           ...model,
    //           riskFormData: {
    //             ...model.riskFormData,
    //             [sectionName]: updatedSectionWithCategory
    //           }
    //         }
    //       }
    //     };
    
    //   } else {
    //     // Handle direct fields under the section
    //     const fieldKey = Object.keys(section).find(key => section[key].name === fieldName);
    //     if (!fieldKey) {
    //       console.error('Field not found', fieldName);
    //       return state;
    //     }
    
    //     const updatedField = {
    //       ...section[fieldKey],
    //       value: (section[fieldKey].type === 'multiselect' && !Array.isArray(value)) ? [value] : value,
    //       description: description || section[fieldKey].description
    //     };
    
    //     const updatedSection = {
    //       ...section,
    //       [fieldKey]: updatedField
    //     };
    
    //     return {
    //       ...state,
    //       models: {
    //         ...state.models,
    //         [modelId]: {
    //           ...model,
    //           riskFormData: {
    //             ...model.riskFormData,
    //             [sectionName]: updatedSection
    //           }
    //         }
    //       }
    //     };
    //   }
    // }

    case SET_FORM_DATA: {
      const { modelId, sectionName, fieldName, value, category, description } = action.payload;
  
      console.log('payload in set form data reducer', action.payload);
    
      if (!state.models[modelId]) {
        console.error('Model not found', modelId);
        return state;
      }
    
      const model = { ...state.models[modelId] };
      const section = model.riskFormData[sectionName];
    
      if (!section) {
        console.error('Section not found', sectionName);
        return state;
      }
    
      if (category) {
        // When a category is present, handle nested fields
        if (!section[category]) {
          console.error('Category not found', category);
          return state;
        }
    
        const fieldKey = Object.keys(section[category]).find(key => section[category][key].name === fieldName);
        if (!fieldKey) {
          console.error('Field not found in category', fieldName);
          return state;
        }
    
        // Update the nested field in the category
        const updatedField = {
          ...section[category][fieldKey],
          value: (section[category][fieldKey].type === 'multiselect' && !Array.isArray(value)) ? [value] : value,
          description: description || section[category][fieldKey].description
        };
  
        console.log('updated field in set form data reducer', updatedField);
    
        const updatedCategory = {
          ...section[category],
          [fieldKey]: updatedField
        };
        console.log('updated category in set form data reducer', updatedCategory);
    
        const updatedSectionWithCategory = {
          ...section,
          [category]: updatedCategory
        };
        console.log('updated category and field in set form data reducer', updatedSectionWithCategory);
    
        // Update the model with the new section data including the category
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...model,
              riskFormData: {
                ...model.riskFormData,
                [sectionName]: updatedSectionWithCategory
              }
            }
          }
        };
    
    //   } else {
    //     // Handle direct fields under the section
    //     const updatedSection = section.map(field => {
    //       if (field.name === fieldName) {
    //         return {
    //           ...field,
    //           value: (field.type === 'multiselect' && !Array.isArray(value)) ? [value] : value,
    //           description: description || field.description
    //         };
    //       }
    //       return field;
    //     });
    
    //     return {
    //       ...state,
    //       models: {
    //         ...state.models,
    //         [modelId]: {
    //           ...model,
    //           riskFormData: {
    //             ...model.riskFormData,
    //             [sectionName]: updatedSection
    //           }
    //         }
    //       }
    //     };
    //   }
    // }
  
  } else {
    // Handle direct fields under the section when section is an object with numeric keys
    const updatedSection = { ...section }; // Ensure it's a shallow copy of the section object

    const fieldKey = Object.keys(updatedSection).find(key => updatedSection[key].name === fieldName);
    if (fieldKey) {
      updatedSection[fieldKey] = {
        ...updatedSection[fieldKey],
        value: (updatedSection[fieldKey].type === 'multiselect' && !Array.isArray(value)) ? [value] : value,
        description: description || updatedSection[fieldKey].description
      };
    } else {
      console.error('Field not found', fieldName);
      return state;
    }

    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: {
          ...model,
          riskFormData: {
            ...model.riskFormData,
            [sectionName]: updatedSection
          }
        }
      }
    };
  }
}
  
case FETCH_MODEL_RISKS_SUCCESS:
  const { modelId, risks } = action.payload;
  const updatedModel = state.models[modelId] || { riskFormData: {} };

  risks.forEach(risk => {
    const section = risk.active_section;
    // Initialize the section if it doesn't already exist
    if (!updatedModel.riskFormData[section]) {
      updatedModel.riskFormData[section] = { sectionRisks: [] };
    }
    // Merge new risks with existing risks, preventing duplicates and updating existing entries
    updatedModel.riskFormData[section].sectionRisks = _.unionBy(
      updatedModel.riskFormData[section].sectionRisks,
      [{
        riskId: risk.risk_id,
        riskName: risk.risk_name,
        priority: risk.priority,
        rationale: risk.rationale
      }],
      'riskId' // Specify the property to compare for uniqueness
    );
  });

  return {
    ...state,
    models: {
      ...state.models,
      [modelId]: updatedModel
    }
  };

      
        case 'risks/fetchRisksForModel/fulfilled': {
          const modelId = action.meta.arg.modelId;
          const sectionName=action.meta.arg.sectionName;
          const fetchedRisks = action.payload;
          
          // Transform fetched risks into the desired structure
          const risksForSection = fetchedRisks.map(risk => ({
            riskName: risk.risk_name,
            riskId: risk.risk_id,
            priority: risk.priority,
            rationale: risk.rationale,
            status: 'Saved'
          }));
        
          // Update the state with the new risks in the correct section
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: risksForSection
                  }
                },
                loading: false,
              },
            },
          };
        }
        
        
        
        case 'risks/fetchRisksForModel/rejected': {
          const { modelId } = action.meta.arg; // Correctly destructure modelId
          // Update the state to indicate an error
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                loading: false,
                error: action.error.message,
              },
            },
          };
        }
        case 'risks/updateActiveSections': {
          console.log('Received updateActiveSections action:', action);
          const { modelId, sections } = action.payload;
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                activeSections:sections , 
              },
            },
          };
        }
        
        case SET_RISK_PRIORITY_START:{
          const { modelId, sectionName, riskId } = action.payload;
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: state.models[modelId].riskFormData[sectionName].sectionRisks.map(risk =>
                      risk.riskId === riskId ? { ...risk, loading: true } : risk
                    )
                  }
                }
              },
            },
          }; 
        }
        
        case SET_RISK_PRIORITY_SUCCESS:{
          console.log('Prio Reducer received action payload:', action.payload);
          const { modelId, sectionName, riskId, priority, rationale } = action.payload;
          return {
            ...state,
            
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                loading: false,  
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: state.models[modelId].riskFormData[sectionName]?.sectionRisks.map(risk => {
                      if (risk.riskId !== riskId) return risk;
                      return {
                        ...risk,
                        loading: false,
                        priority: priority,
                        rationale: rationale
                      };
                    })
                  }
                }
              },
            },
          };
        }
        case SET_RISK_PRIORITY_FAILURE: {
          
          const { modelId, sectionName, riskId, error} = action.payload;
          return {
              ...state,
              models: {
                  ...state.models,
                  [modelId]: {
                      ...state.models[modelId],
                      riskFormData: {
                          ...state.models[modelId].riskFormData,
                          [sectionName]: {
                              ...state.models[modelId].riskFormData[sectionName],
                              sectionRisks: state.models[modelId].riskFormData[sectionName]?.sectionRisks.map(risk => {
                                  if (risk.riskId === riskId) {
                                      return {
                                          ...risk,
                                          loading: false,
                                          error:error // Ensure loading is also set to false when there is an error
                                      };
                                  }
                                  return risk;
                              })
                          }
                      }
                  },
              },
          };
      }
         
         
          case UPDATE_RISK_PRIORITY:{
            const { modelId, sectionName, riskId, priority } = action.payload;
          
            return {
              ...state,
              models: {
                ...state.models,
                [modelId]: {
                  ...state.models[modelId],
                  riskFormData: {
                    ...state.models[modelId].riskFormData,
                    [sectionName]: {
                      ...state.models[modelId].riskFormData[sectionName],
                      sectionRisks: state.models[modelId].riskFormData[sectionName]?.sectionRisks.map(risk => {
                        if (risk.riskId !== riskId) return risk;
                        return { ...risk, priority: priority };
                      })
                    }
                  }
                },
              },
            };
          }


   
    case UPDATE_RISKS_AFTER_SELECTION:{
      
      if (!newState.models[modelId]) {
        return newState;
      }
      newState.models[modelId].risks = action.payload;
      return newState;
    }
     
      case DISCARD_RISKS: {
        const { modelId, sectionName, risk } = action.payload;
        const model = state.models[modelId];
        if (!model) {
          console.error("Model not found in state", modelId);
          return state;
        }
  
        const updatedRisks = model.riskFormData[sectionName].sectionRisks.map(r =>
          r.riskName === risk.riskName ? { ...r, status: 'Discarded' } : r
        );
  
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...model,
              riskFormData: {
                ...model.riskFormData,
                [sectionName]: {
                  ...model.riskFormData[sectionName],
                  sectionRisks:updatedRisks
                }
              }
            }
          }
        };
      }
      case DISCARD_UNSELECTED_RISKS: {
        const { risksToDiscard, modelId } = action.payload;
  
        // Get existing discardedRisks for the model
        const existingDiscardedRisks = newState.models[modelId].discardedRisks || [];
  
        const newDiscardedRisks = [...new Set([...existingDiscardedRisks, ...risksToDiscard])];
  
        return {
          ...newState,
          models: {
            ...newState.models,
            [modelId]: {
              ...newState.models[modelId],
              discardedRisks: newDiscardedRisks,
            },
          },
        };
      }

      



    case FETCH_RISKS_START: {
      const { modelId, sectionsToFetch } = action.payload;
    
      if (!newState.models[modelId]) {
        console.error(`Model with ID ${modelId} not found.`);
      } else {
        // Ensure the sectionLoading state is initialized for the model
        if (!newState.models[modelId].sectionLoading) {
          newState.models[modelId].sectionLoading = {};
        }
    
        // Update the loading state for each section in sectionsToFetch to true
        sectionsToFetch.forEach(section => {
          newState.models[modelId].sectionLoading[section] = true;
        });
      }
    
      return newState;
    }
    
   
    case FETCH_RISKS_FAILURE: {
      const { modelId, sectionsToFetch } = action.payload;
    
      // Check if the model exists and if sectionLoading is initialized
      if (newState.models[modelId] && newState.models[modelId].sectionLoading) {
        // Update the loading state for each section in sectionsToFetch to false
        sectionsToFetch.forEach(section => {
          newState.models[modelId].sectionLoading[section] = false;
        });
      } else {
        console.error(`Model with ID ${modelId} not found or sectionLoading not initialized.`);
      }
    
      return newState;
    }
    case FETCH_RISK_CARD_DATA_START:
    return {
      ...newState,
      models: {
        ...newState.models,
        [action.payload.modelId]: {
          ...newState.models[action.payload.modelId],
          loading: true,
        },
      },
    }
 
 

  case FETCH_RISK_CARD_DATA_SUCCESS: {
    const { modelId, data } = action.payload;
  
    if (!newState.models || !newState.models[modelId]) {
      console.error(`Model with ID ${modelId} not found.`);
      return newState;
    }

    console.log ('Raw data received at fetch rc data reducer;', data)
  
   

    const mergeFormData = (existingFormData, newData) => {
      const updatedFormData = { ...existingFormData };
    
      Object.keys(newData).forEach(section => {
        console.log(`Processing section: ${section}`);
    
        // Initialize section if not present
        if (!updatedFormData[section]) {
          updatedFormData[section] = {};
        }
    
        let sectionData;
        if (typeof newData[section] === 'string') {
          try {
            sectionData = JSON.parse(newData[section]);
            console.log(`Parsed section data for ${section}:`, sectionData);
          } catch (e) {
            console.error(`Error parsing section data for ${section}:`, e);
            sectionData = {}; // Fallback to an empty object if parsing fails
          }
        } else {
          sectionData = newData[section];
          console.log(`Section data is an object for ${section}:`, sectionData);
        }
    
        if (Array.isArray(sectionData)) {
          // Section data is an array, merge array items based on the 'name' key
          updatedFormData[section] = sectionData.map(newField => {
            const existingField = updatedFormData[section].find(f => f.name === newField.name) || {};
            return {
              ...existingField,
              ...newField,
              value: newField.value || existingField.value
            };
          });
        } else if (typeof sectionData === 'object') {
          // Section data is an object, merge categories
          Object.keys(sectionData).forEach(category => {
            console.log(`Processing category: ${category}`);
    
            if (!updatedFormData[section][category]) {
              updatedFormData[section][category] = [];
            }
    
    //         const categoryFields = sectionData[category];
    
    //         // Ensure categoryFields is an array
    //         if (Array.isArray(categoryFields)) {
    //           updatedFormData[section][category] = categoryFields.map(newField => {
    //             const existingField = updatedFormData[section][category].find(f => f.name === newField.name) || {};
    //             return {
    //               ...existingField,
    //               ...newField,
    //               value: newField.value || existingField.value
    //             };
    //           });
    //         } else {
    //           console.error(`Expected array for category ${category} in section ${section} but found:`, categoryFields);
    //         }
    //       });
    //     } else {
    //       console.error(`Unexpected data type for section ${section}:`, sectionData);
    //     }
    //   });
    
    //   return updatedFormData;
    // };
    
    let categoryFields = sectionData[category];

        // Convert categoryFields to an array if it's an object with numeric keys
        if (!Array.isArray(categoryFields) && typeof categoryFields === 'object') {
          categoryFields = Object.values(categoryFields);
        }

        // Ensure categoryFields is an array
        if (Array.isArray(categoryFields)) {
          updatedFormData[section][category] = categoryFields.map(newField => {
            const existingField = updatedFormData[section][category].find(f => f.name === newField.name) || {};
            return {
              ...existingField,
              ...newField,
              value: newField.value || existingField.value
            };
          });
        } else {
          console.error(`Expected array for category ${category} in section ${section} but found:`, categoryFields);
        }
      });
    } else {
      console.error(`Unexpected data type for section ${section}:`, sectionData);
    }
  });

  return updatedFormData;
};

  
  
    // Use the mergeFormData function to update the riskFormData
    const updatedModelRiskFormData = mergeFormData(newState.models[modelId].riskFormData, data);
  
   // console.log('Risk card data after merge:', JSON.stringify(updatedModelRiskFormData));
  
    // Create a new state with the updated model's riskFormData
    return {
      ...newState,
      models: {
        ...newState.models,
        [modelId]: {
          ...newState.models[modelId],
          riskFormData: updatedModelRiskFormData,
          loading: false, // Set loading to false after data is fetched
        },
      },
    };
  }
  

  case FETCH_RISK_CARD_DATA_FAILURE:{
    return {
      ...newState,
      models: {
        ...newState.models,
        [action.payload.modelId]: {
          ...newState.models[action.payload.modelId],
          error: action.payload.error,
          loading: false,
        },
      },
    };
  }

      case SET_STEP: {
        const { modelId, sectionName, stepName } = action.payload;
      console.log ('payload in set_step reducer', action.payload)
        // Check if the model and section exist
        if (state.models[modelId].riskFormData[sectionName]) {
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    currentStep: stepName  // Update the current step in the specific section
                  }
                }
              }
            }
          };
        }
      
        // If the model or section does not exist, return the unchanged state
        return state;
      }
      

      case UPDATE_ASSESSMENT_STEP_SUCCESS:{
        let {currentStep } = action.payload;
        // Ensure that the model exists in the state before trying to update it
        if (newState.models[modelId]) {
          return {
            ...newState,
            models: {
              ...newState.models,
              [modelId]: {
                ...newState.models[modelId],
                step: currentStep, // Update the step within the model
                // Any other assessment-related updates can go here
              }
            }
          };
        }
        return state; // Return the unchanged state if the modelId doesn't exist
      }
       
  
  case UPDATE_ASSESSMENT_STEP_FAILURE:{
    const { error } = action.payload;
    if (state.models[modelId]) {
      return {
        ...newState,
        models: {
          ...newState.models,
          [modelId]: {
            ...newState.models[modelId],
            error: error, // Store the error within the model
          },
        },
      };
    }
    return state;
    }

    case ASSESSMENT_STATUS_UPDATED:{
      const { modelId, assessmentStatus } = action.payload;
      console.log ('assessment status received at reducer', action.payload)
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            assessmentStatus: assessmentStatus
          }
        }
      };
    }
    case ASSESSMENT_STATUS_FAILED:{
      return {
        ...state,
        models: {
          ...state.models,
          [action.payload.modelId]: {
            ...state.models[action.payload.modelId],
            error: action.payload.error
          }
        }
      };
    }
  
      case UPDATE_RISK_NAME: {
        const { modelId, sectionName, originalName, newName } = action.payload;
        console.log ('update risk in state payload:', action.payload)
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              riskFormData: {
                ...state.models[modelId].riskFormData,
                [sectionName]: {
                  ...state.models[modelId].riskFormData[sectionName],
                  sectionRisks: state.models[modelId].riskFormData[sectionName].sectionRisks.map(risk =>
                    risk.riskName === originalName ? { ...risk, riskName: newName } : risk
                  )
                }
              }
            }
          }
        };
      }
      
  
      case SET_RISK_EVALUATION: {
        const { modelId,riskId,themeId, evaluationData,sectionName } = action.payload;
              
        if (!newState.models[modelId]?.riskFormData[sectionName]?.sectionRisks) {
          console.error(`Model with ID ${modelId} not found or sectionRisks not initialized.`);
          return newState;
        }
      
    // Deep clone the models to prevent direct state mutation.
    const updatedModels = JSON.parse(JSON.stringify(newState.models));
    
    const sectionRisks = updatedModels[modelId].riskFormData[sectionName]?.sectionRisks;
    const targetRisk = sectionRisks.find(risk => risk.riskId === riskId);
  
    if (targetRisk) {
      if (!targetRisk.evaluationData) {
        // No existing evaluation data, initialize it
        targetRisk.evaluationData = {};
      }
      // Update the evaluation data for the specific theme
      targetRisk.evaluationData[themeId] = {
        ...targetRisk.evaluationData[themeId],
        ...evaluationData
      };
    } else {
      console.error(`Risk with name ${riskId} not found in sectionRisks.`);
    }
  
    return {
      ...newState,
      models: updatedModels
    };
  }
  case UPDATE_CONTROL_IN_SELECTED_RISK:{
  const { modelId, riskName, controlData } = action.payload;
  //console.log("Reducer Payload for selected controls: ", action.payload);
    return {
      ...newState,
      models: {
        ...newState.models,
        [modelId]: {
          ...newState.models[modelId],
          savedRisks: newState.models[modelId].savedRisks.map(risk => {
            if (risk.riskName === riskName) {
              return {
                ...risk,
                selectedControls: controlData
              };
            }
            return risk;
          })
        }
      }
    }
    };
  

  
  case UPDATE_RISK_EVALUATION: {
    const { modelId, riskId, sectionName, themeId, attribute, property, value, priority, rationale } = action.payload;
  console.log ('priority in update risk eval reducer' ,priority,'and rationale:', rationale)
    if (!newState.models[modelId]?.riskFormData[sectionName]?.sectionRisks) {
      console.error(`Model with ID ${modelId} not found or sectionRisks not initialized.`);
      return newState;
    }
  
    const updatedModels = { ...newState.models };
    const updatedSectionRisks = updatedModels[modelId].riskFormData[sectionName]?.sectionRisks.map(risk => {
      if (risk.riskId === riskId) {
        // Ensure evaluationData is initialized for the risk
        const evaluationData = risk.evaluationData || {};
        // Ensure a themeId entry exists in evaluationData
        const themeEvaluation = evaluationData[themeId] || {};
        // Update the specified attribute and property
        themeEvaluation[attribute] = { ...(themeEvaluation[attribute] || {}), [property]: value };
        // Update the evaluationData with the new theme evaluation
        evaluationData[themeId] = themeEvaluation;
  
        return { 
          ...risk,
           evaluationData,
           priority, // Update priority directly on the risk
           rationale 
           };
      }
      return risk;
    });

    console.log ('updated risk array after eval update', updatedSectionRisks)
  
    return {
      ...newState,
      models: {
        ...newState.models,
        [modelId]: {
          ...newState.models[modelId],
          riskFormData: {
            ...newState.models[modelId].riskFormData,
            [sectionName]: {
              ...newState.models[modelId].riskFormData[sectionName],
              sectionRisks: updatedSectionRisks // Update the section risks
            }
          }
        }
      }
    };
  }
  
      case FETCH_RISK_EVALUATIONS:
        // Set loading to true when the request starts
        return {
          ...state,
          loading: true,
          error: null // Reset errors on a new request
        };
  

  case FETCH_RISK_EVALUATIONS_SUCCESS: {
    const { modelUuid, evaluations, sectionName } = action.payload;
    console.log ('update risk eval reducer receives', action.payload)
    const evaluationCriteria = JSON.parse(JSON.stringify(evaluationForm)); // Ensure this form is defined or imported correctly
    const impactSection = evaluationCriteria.find(section => section.attribute === "impact");

    const evaluationsForRiskId = (evaluations, riskId) => {
      return Object.keys(evaluations)
        .filter(key => key.startsWith(`${riskId}-`)) // Filter keys starting with the riskId followed by '-'
        .reduce((acc, key) => {
          // Merge all relevant evaluations into a single array
          return acc.concat(evaluations[key]);
        }, []);
    }
    
    return {
      ...state,
      models: {
        ...state.models,
        [modelUuid]: {
          ...state.models[modelUuid],
          riskFormData: {
            ...state.models[modelUuid].riskFormData,
            [sectionName]: { // Directly accessing the specified section
              ...state.models[modelUuid].riskFormData[sectionName],
              sectionRisks: state.models[modelUuid].riskFormData[sectionName].sectionRisks.map(risk => {
                const riskEvaluations = evaluationsForRiskId(evaluations, risk.riskId);
                const newEvaluationData = { ...risk.evaluationData };

                riskEvaluations.forEach(evaluation => {
                  const themeId = evaluation.theme_id;
                  newEvaluationData[themeId] = newEvaluationData[themeId] || {};
                  newEvaluationData[themeId].impact = newEvaluationData[themeId].impact || {};

                  // Assuming evaluationCriteria is structured to allow direct access to evaluation properties by themeId
                  impactSection.properties.forEach(property => {
                    newEvaluationData[themeId].impact[property.name] = evaluation[property.name] || property.value;
                });
            });


                return {
                  ...risk,
                  evaluationData: newEvaluationData
                };
              })
            }
          }
        }
      }
    };
  }


  
      case FETCH_RISK_EVALUATIONS_FAILURE:
        // Handle the failure state
        return {
          ...state,
          loading: false,
          error: action.error // Store the error message
        };
        
        case UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS: {
          // Extract modelId from the action payload
          const { modelId, uniqueSections } = action.payload;
        console.log('action payloand in act sec reducer', action.payload)
          // Check if the modelId exists in the state
          if (state.models[modelId]) {
            return {
              ...state,
              models: {
                ...state.models,
                [modelId]: {
                  ...state.models[modelId],
                  // Update the activeSections with the unique sections derived from saved risks
                  activeSections: uniqueSections,
                },
              },
            };
          }
        
          // Return the unchanged state if the modelId does not exist
          return state;
        }
    
        
       
        case MOVE_RISK_TO_SAVED: {
          const { modelId, sectionName, risk } = action.payload;
          console.log ('moving risk to save with details from DB', action.payload)
          const model = state.models[modelId];
          if (!model) {
            console.error("Model not found in state", modelId);
            return state;
          }
    
          const sectionRisks = model.riskFormData[sectionName].sectionRisks.map(r =>
            r.riskName === risk.riskName ? { ...r, riskId: risk.riskId, status: 'Saved' } : r
          );
          console.log ('sectionrisks to update with new saved risk', sectionRisks)
    
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...model,
                riskFormData: {
                  ...model.riskFormData,
                  [sectionName]: {
                    ...model.riskFormData[sectionName],
                    sectionRisks
                  }
                }
              }
            }
          };
        }
       
       
        case UPDATE_SAVED_RISK: {
          const { modelId, sectionName, riskId, newDetails } = action.payload;
        
          // Log the payload for debugging purposes (remove or adjust logging for production)
          console.log('Updating risk with payload:', action.payload);
        
          // Validate if model exists and the section is correctly provided
          if (!state.models[modelId] || !state.models[modelId].riskFormData[sectionName]) {
            console.error(`No model with ID ${modelId} or section ${newDetails.section} exists.`);
            return state; // Return the current state if the model or section doesn't exist
          }
        
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: state.models[modelId].riskFormData[sectionName].sectionRisks.map(risk =>
                      risk.riskId === riskId ? { ...risk, ...newDetails } : risk
                    )
                  }
                }
              }
            }
          };
        }
        
        
        
        
        // case DISCARD_SAVED_RISK: {
        //   const { riskId, modelId, sectionName } = action.payload;
        // console.log('payload in saved risk discard reducer', action.payload)
        //   return {
        //     ...state,
        //     models: {
        //       ...state.models,
        //       [modelId]: {
        //         ...newState.models[modelId],
        //         riskFormData: {
        //           ...newState.models[modelId].riskFormData,
        //           [sectionName]: {
        //             ...newState.models[modelId].riskFormData[sectionName],
        //             sectionRisks: state.models[modelId].riskFormData[sectionName].sectionRisks.filter(risk => risk.riskId !== riskId),
        //           }
        //         }
        //       }
        //     }
        //   };
        // }
              
        case DISCARD_SAVED_RISK: {
          const { riskId, modelId, sectionName } = action.payload;
          console.log('payload in saved risk discard reducer', action.payload);
          const currentModel = state.models[modelId];
          const currentSectionRisks = currentModel.riskFormData[sectionName].sectionRisks;
      
          // Map over sectionRisks to update the status of the specified risk
          const updatedRisks = currentSectionRisks.map(risk => {
              if (risk.riskId === riskId) {
                  return { ...risk, status: 'Discarded' }; // Update the status to 'Discarded'
              }
              return risk;
          });
      
          return {
              ...state,
              models: {
                  ...state.models,
                  [modelId]: {
                      ...currentModel,
                      riskFormData: {
                          ...currentModel.riskFormData,
                          [sectionName]: {
                              ...currentModel.riskFormData[sectionName],
                              sectionRisks: updatedRisks,
                          }
                      }
                  }
              }
          };
      }
      
        
        case FETCH_SECTION_SPECIFIC_RISKS_START: {
          const { modelId, sectionName } = action.payload;
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                sectionLoading: {
                  ...state.models[modelId].sectionLoading,
                  [sectionName]: true,
                },
              },
            },
          };
        }
        case FETCH_SECTION_SPECIFIC_RISKS_SUCCESS: {
          const { modelId, sectionName, risks } = action.payload;
          console.log ('Generated risks to enter into state', risks)
          const updatedRisks = risks.map(risk => ({
            ...risk,
            status: risk.status || 'Generated'  // Ensure each risk has a 'Generated' status if none is present
          }));
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: updatedRisks,
                  },
                },
                sectionLoading: {
                  ...state.models[modelId].sectionLoading,
                  [sectionName]: false,
                },
              },
            },
          };
        }
        case FETCH_SECTION_SPECIFIC_RISKS_FAILURE: {
          const { modelId, sectionName, error } = action.payload;
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                sectionLoading: {
                  ...state.models[modelId].sectionLoading,
                  [sectionName]: false,
                },
                error,
              },
            },
          };
        }     
        case REFRESH_RISKS_SUCCESS: {
          const { modelId, sectionName, newRisks } = action.payload;
          const existingRisks = state.models[modelId]?.riskFormData[sectionName]?.sectionRisks || [];
        
          // Filter out only the generated risks and replace them with new ones
          const preservedRisks = existingRisks.filter(risk => risk.status !== 'Generated');
        
          // Add 'Generated' status to new risks if missing
          const updatedNewRisks = newRisks.map(risk => ({
            ...risk,
            status: risk.status || 'Generated',
            riskName:risk.riskName
          }));
        
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: [...preservedRisks, ...updatedNewRisks],
                  },
                },
                sectionLoading: {
                  ...state.models[modelId].sectionLoading,
                  [sectionName]: false,
                },
              },
            },
          };
        }

        case UPDATE_SECTION_PROGRESS:
          const { sectionName: updatedSectionName, progress } = action.payload;
          return {
            ...state,
            models: {
              ...state.models,
              [action.payload.modelId]: {
                ...state.models[action.payload.modelId],
                riskFormData: {
                  ...state.models[action.payload.modelId].riskFormData,
                  [updatedSectionName]: {
                    ...state.models[action.payload.modelId].riskFormData[updatedSectionName],
                    assessmentProgress: progress,  // Store progress directly in the section
                  },
                },
              },
            },
          };
        
  
      default:
        return state;
    }
  };
  

export default riskReducer;
